import { combineReducers } from 'redux';

import podcast from './podcast';
import audioEditor from './audioEditor';
// // import { IAudioEditor } from '../../../types';
// // import { AudioEditorActions } from '../../types/audioEditor';
// // import { IPodcastState, PodcastActions } from '../../types/podcast';

// // interface RootState {
// //   podcast: IPodcastState;
// //   audioEditor: IAudioEditor;
// // }

// // type RootReducer = Reducer<RootState, PodcastActions | AudioEditorActions>;

const podcasterRootReducer = combineReducers({
  podcast,
  audioEditor,
});

export default podcasterRootReducer;
