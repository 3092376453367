import React from 'react';

const SearchIcon = () => (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.93945 0.931274C4.98074 0.931274 0.941406 4.9706 0.941406 9.92934C0.941406 14.888 4.98074 18.9352 9.93945 18.9352C12.0575 18.9352 14.0054 18.193 15.5449 16.9606L19.293 20.7067C19.4821 20.888 19.7347 20.988 19.9967 20.9853C20.2587 20.9827 20.5093 20.8775 20.6947 20.6924C20.8801 20.5072 20.9856 20.2569 20.9886 19.9949C20.9917 19.7329 20.892 19.4801 20.7109 19.2907L16.9629 15.5427C18.1963 14.0008 18.9395 12.0498 18.9395 9.92934C18.9395 4.9706 14.8982 0.931274 9.93945 0.931274ZM9.93945 2.9313C13.8173 2.9313 16.9375 6.0515 16.9375 9.92934C16.9375 13.8072 13.8173 16.9352 9.93945 16.9352C6.06162 16.9352 2.94141 13.8072 2.94141 9.92934C2.94141 6.0515 6.06162 2.9313 9.93945 2.9313Z'
      fill='#797BA1'
    />
  </svg>
);

export default SearchIcon;
