import React, { FC, useEffect, useState } from 'react';
// import Discover from "../../assets/svg/dashboard/light/Discover.svg";

const IconComponent: FC<{ path: string; name?: string; className?: string }> = ({
  path,
  name,
  className,
}) => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    import(`../../assets/svg/${path}`) // Assuming your icons are in the "icons" folder
      .then((image) => setIcon(image.default))
      .catch((err) => console.error('Icon not found:', err));
  }, [path]);

  return icon ? <img src={icon} alt={name} className={className} /> : null;
};

export default IconComponent;
