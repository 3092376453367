import axios from 'axios';
import { TOASTER_STATUS } from '../../constant';
import { EPISODE_API_ROUTES } from '../../constant/apiRoute';
import AxiosClient from '../../services/AxiosClient';
import { showToastMessage } from '../../utils';

interface AdminLoginVerifierInterface {
  userName: string;
  password: string;
}

export interface EnhancementUrl {
  inputFileObject: string;
  presignedUrlForInput: string;
  presignedUrlForOutput: string;
  enhancedAudioPath: string;
}
// API base URL and key
const API_AUDIO_BASE_URL = process.env.REACT_APP_BASE_URL;
const API_AUDIO_KEY = process.env.REACT_APP_API_AUDIO_KEY;

// Define the headers object with the API key
const headers = {
  headers: {
    'x-api-key': API_AUDIO_KEY,
  },
};

// Function to download audio file
export const audioDownload = async (downloadPath: string, callBack: (data: any) => void) => {
  const response = await axios.get(`${API_AUDIO_BASE_URL}${downloadPath}`, {
    ...headers,
    responseType: 'arraybuffer',
    onDownloadProgress: callBack,
  });

  return response;
};

export const getEnhancePresignedUrl = async (
  url: string,
  handleData: (data: EnhancementUrl) => void,
  handleError: () => void
) => {
  try {
    const response = await AxiosClient.post(EPISODE_API_ROUTES.ENHANCE_PRESIGNED_URL, {
      inputFileObject: url,
    });
    if (response.data?.success && !response.data.result?.errorMessage)
      handleData(response.data.result);
    else {
      showToastMessage(TOASTER_STATUS.ERROR, response?.data?.result?.errorMessage);
      handleError();
    }
  } catch (error: any) {
    handleError();
    showToastMessage(TOASTER_STATUS.ERROR, error.response?.data?.result?.errorMessage);
  }
};

const pollForStatus = async (
  generatedFileName: string,
  accessToken: string,
  attemptCount: number = 0,
  maxAttempts: number = 20
): Promise<void> => {
  // If the maximum number of attempts is reached, throw an error
  if (attemptCount >= maxAttempts) {
    throw new Error('Audio processing timed out after too many attempts');
  }

  const { data: status } = await axios.get(
    `${API_AUDIO_BASE_URL}api/audio/media/${generatedFileName}/status`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (status === 'IN_PROGRESS') {
    // Wait for 2 seconds before polling again
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    // Recursively call the function to poll again, increasing the attempt count
    await pollForStatus(generatedFileName, accessToken, attemptCount + 1, maxAttempts);
  } else if (status === 'FAILED') {
    throw new Error('Audio processing failed');
  }
  // Return here if status is 'READY' or no further processing is needed
};

// Function to remove noise from audio

export const removeNoise = async (input: string, output: string): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('accessToken'); // Getting the access token from local storage

    // Calling the /remove-noise endpoint from JavaAPI with authorization header and fileUrl as a query parameter
    const { data: noiseRemovalResponse } = await axios.post(
      `${API_AUDIO_BASE_URL}api/audio/remove-noise`,
      null,
      {
        params: { fileUrl: input }, // Send fileUrl as a query parameter
        headers: {
          Authorization: `Bearer ${accessToken}`, // Set the authorization header
        },
      }
    );

    if (!accessToken) {
      throw new Error('Access token not found. Please log in.');
    }
    // Extract the generated file name from the response
    const { generated_name: generatedFileName } = noiseRemovalResponse;

    // Poll for status using a separate function
    await pollForStatus(generatedFileName, accessToken);

    // Once ready, retrieve the processed audio file
    const { data: processedAudio } = await axios.get(
      `${API_AUDIO_BASE_URL}api/audio/media/${generatedFileName}`,
      {
        params: { output },
        headers: {
          Authorization: `Bearer ${accessToken}`, // Set the authorization header
        },
      }
    );

    return processedAudio;
  } catch (error) {
    console.error('Error during noise removal:', error);
    throw error;
  }
};

// Function to check audio processing status
export const audioStatus = async (jobId: string): Promise<any> => {
  const response = await axios.get(`${API_AUDIO_BASE_URL}api/audio/media/${jobId}/status`);
  return response;
};
