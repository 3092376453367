import { v4 as UUID } from 'uuid';
import { EditorActions } from '../../../constant/audioEditor';
import { EditorChangeHistory, ExtendedEditorAudio, IAudioEditor } from '../../../types';
import {
  SET_AUDIO_TRACKS_LIST,
  DUPLICATE_AUDIO_TRACK,
  SPLIT_AUDIO,
  SET_VOLUME,
  UPDATE_AUDIO_NAME,
  LOCK_AUDIO_TRACK,
  ADD_AUDIO_TRACK,
  SET_EDITOR_EPISODES_LIST,
  SET_AUDIO_SPEED,
  SET_MUSIC_TRACK_LIST,
  DELETE_AUDIO_TRACK,
  UNDO_AUDIO_TRACK,
  DRAG_AUDIO_TRACK,
  RESET_AUDIO_EDITOR,
  AUDIO_ACTION,
  AUDIO_LOADING,
  LAST_ACTION,
  ACTION_WITHOUT_SAVE_CHANGES,
  CHANGES_SAVED,
  ACTIVE_AUDIO_TRACK,
  SET_CURRENT_PLAY_TIME,
  REPLACE_DATA,
  AUDIO_PLAYING,
  SET_AUDIO_DURATION,
} from '../../constants/podcaster/audioEditor';
import { AudioEditorActions } from '../../types/audioEditor';
import { getSpeedDuration } from '../../../services/utils';

export const initialAudioState: ExtendedEditorAudio = {
  duration: 0,
  isLoading: false,
  isPaid: false,
  locked: false,
  name: '',
  order: 1,
  referenceId: '',
  speed: 1,
  startTime: 0,
  trackId: '',
  url: '',
  volume: 1,
};

const initialState: IAudioEditor = {
  tracks: [],
  renameAudioId: '',
  episodeList: [],
  musicList: [],
  episodeId: '',
  deletedTracks: [],
  history: null,
  lastAction: {
    action: '',
    id: '',
  },
  currentAction: '',
  actionWithoutSaveChanges: {
    type: '',
    actionData: '',
  },
  changesSaved: true,
  activeAudioTrack: '',
  currentTime: 0,
  isPlaying: false,
};

const getIndex = (itemList: any[], key: string, compareValue: string): number =>
  itemList.findIndex((item) => item[key] === compareValue);

const rearrangeOrder = (
  newOrderNumber: number,
  tracks: ExtendedEditorAudio[]
): ExtendedEditorAudio[] => {
  const newList = [...tracks];

  return newList.map((item) => ({
    ...item,
    order: item.order >= newOrderNumber ? item.order + 1 : item.order,
  }));
};

const handleCustomTrackDataUpdate = (
  state: IAudioEditor,
  id: string,
  key: keyof ExtendedEditorAudio,
  value: any,
  changesSaved: boolean,
  history: EditorChangeHistory | null
): IAudioEditor => {
  const tracksData: ExtendedEditorAudio[] = [...state.tracks];
  const index = getIndex(tracksData, 'referenceId', id);
  tracksData[index] = { ...tracksData[index], [key]: value };

  return {
    ...state,
    changesSaved,
    history,
    tracks: tracksData,
  };
};

const handleSpeedChange = (
  state: IAudioEditor,
  data: { value: number; id: string }
): IAudioEditor => {
  const tracksList = [...state.tracks];
  const index = getIndex(tracksList, 'referenceId', data.id);
  tracksList[index].speed = data.value;
  const filteredData = tracksList
    .filter(
      (track) =>
        track.trackId === tracksList[index].trackId && track.startTime > tracksList[index].startTime
    )
    .sort((a, b) => a.startTime - b.startTime);
  let endTime =
    tracksList[index].startTime +
    getSpeedDuration(tracksList[index].duration, tracksList[index].speed);
  filteredData.forEach((element) => {
    if (element.startTime < endTime) {
      const trackIndex = getIndex(tracksList, 'referenceId', element.referenceId);
      tracksList[trackIndex].startTime = endTime;
      endTime += getSpeedDuration(element.duration, element.speed);
    }
  });
  return { ...state, tracks: tracksList };
};

const handleDuplicateAudioTrack = (
  state: IAudioEditor,
  data: ExtendedEditorAudio
): IAudioEditor => {
  const newDuplicateData: ExtendedEditorAudio = {
    ...data,
    locked: false,
  };

  const newData = rearrangeOrder(data.order, state.tracks);

  delete newDuplicateData?.uuid;

  return {
    ...state,
    changesSaved: false,
    history: {
      id: newDuplicateData.referenceId,
      changesSaved: state.changesSaved,
      trackData: [...state.tracks],
      actionName: EditorActions.DUPLICATE_AUDIO,
    },
    tracks: [...newData, newDuplicateData],
  };
};

const handleSplitAudio = (
  state: IAudioEditor,
  data: {
    track1: ExtendedEditorAudio;
    track2: ExtendedEditorAudio;
    id: string;
  }
): IAudioEditor => {
  const tracksList = [...state.tracks];
  const index = getIndex(tracksList, 'referenceId', data.id);
  tracksList[index] = data.track1;
  tracksList.push(data.track2);

  return {
    ...state,
    changesSaved: false,
    history: {
      changesSaved: state.changesSaved,
      id: data.id,
      trackData: [...state.tracks],
      actionName: EditorActions.SPLIT_AUDIO,
    },
    tracks: tracksList,
  };
};

const handleDeleteAudioTracks = (state: IAudioEditor, id: string): IAudioEditor => {
  const index = getIndex(state.tracks, 'referenceId', id);
  if (index > -1) {
    return {
      ...state,
      changesSaved: false,
      history: {
        changesSaved: state.changesSaved,
        id,
        trackData: [...state.tracks],
        actionName: EditorActions.REMOVE_AUDIO,
      },
      tracks: state.tracks.filter((item) => item.referenceId !== id),
      deletedTracks: Object.keys(state.tracks[index]).includes('uuid')
        ? ([...state.deletedTracks, state.tracks[index].uuid] as string[])
        : [...state.deletedTracks],
    };
  }
  return state;
};

const handleReplaceData = (
  state: IAudioEditor,
  data: { data: ExtendedEditorAudio; id: string; action: EditorActions | '' }
): IAudioEditor => {
  const trackList = [...state.tracks];
  const index = getIndex(trackList, 'referenceId', data.id);
  trackList[index] = data.data;

  const history: EditorChangeHistory = {
    id: data.id,
    changesSaved: state.changesSaved,
    trackData: [...state.tracks],
    actionName: data.action,
  };

  return {
    ...state,
    history: data.action ? history : null,
    tracks: trackList,
  };
};

const audioEditor = (
  // eslint-disable-next-line
  state: IAudioEditor = initialState,
  action: AudioEditorActions
): IAudioEditor => {
  switch (action.type) {
    case SET_EDITOR_EPISODES_LIST:
      return {
        ...state,
        episodeList: action.payload,
      };

    case SET_AUDIO_TRACKS_LIST:
      return {
        ...state,
        tracks: action.payload.tracks.map((item) => ({
          ...initialAudioState,
          ...item,
          isLoading: true,
          volume: item.volume / 10,
          referenceId: UUID(),
          duration: item.duration * item.speed,
        })),
        episodeId: action.payload.episodeId,
      };

    case SET_MUSIC_TRACK_LIST:
      return {
        ...state,
        musicList: action.payload.allMusic,
      };

    case DUPLICATE_AUDIO_TRACK:
      return handleDuplicateAudioTrack(state, action.payload);

    case SPLIT_AUDIO:
      return handleSplitAudio(state, action.payload);

    case SET_VOLUME:
      return handleCustomTrackDataUpdate(
        state,
        action.payload.id,
        'volume',
        action.payload.value,
        false,
        null
      );

    case ACTION_WITHOUT_SAVE_CHANGES:
      return {
        ...state,
        actionWithoutSaveChanges: {
          ...action.payload.value,
        },
      };

    case CHANGES_SAVED:
      return {
        ...state,
        changesSaved: action.payload.value,
      };

    case ACTIVE_AUDIO_TRACK:
      return {
        ...state,
        activeAudioTrack: action.payload.value,
      };

    case UPDATE_AUDIO_NAME:
      return handleCustomTrackDataUpdate(
        state,
        action.payload.id,
        'name',
        action.payload.name,
        false,
        null
      );

    case LOCK_AUDIO_TRACK:
      return handleCustomTrackDataUpdate(
        state,
        action.payload.id,
        'locked',
        action.payload.value,
        false,
        state.history
      );

    case ADD_AUDIO_TRACK:
      return {
        ...state,
        changesSaved: false,
        history: {
          changesSaved: state.changesSaved,
          id: action.payload.referenceId,
          trackData: [...state.tracks],
          actionName: EditorActions.ADD_AUDIO,
        },
        tracks: [
          ...state.tracks,
          {
            ...action.payload,
            duration: action.payload.duration,
          },
        ],
      };

    case SET_AUDIO_SPEED:
      return handleSpeedChange(state, action.payload);

    case AUDIO_ACTION:
      return {
        ...state,
        currentAction: action.payload.value,
      };

    case AUDIO_LOADING:
      return handleCustomTrackDataUpdate(
        state,
        action.payload.id,
        'isLoading',
        action.payload.value,
        state.changesSaved,
        state.history
      );

    case SET_AUDIO_DURATION:
      return handleCustomTrackDataUpdate(
        state,
        action.payload.id,
        'duration',
        action.payload.duration,
        state.changesSaved,
        state.history
      );

    case DELETE_AUDIO_TRACK:
      return handleDeleteAudioTracks(state, action.payload);

    case UNDO_AUDIO_TRACK:
      return {
        ...state,
        tracks: state.history?.trackData ?? [],
        changesSaved: !!state.history?.changesSaved,
        history: null,
      };

    case DRAG_AUDIO_TRACK:
      return handleCustomTrackDataUpdate(
        state,
        action.payload.id,
        'startTime',
        action.payload.startTime,
        false,
        null
      );

    case RESET_AUDIO_EDITOR:
      return {
        ...initialState,
        musicList: state.musicList,
        episodeList: state.episodeList,
      };

    case LAST_ACTION:
      return {
        ...state,
        lastAction: { action: action.payload.value, id: action.payload.id },
      };

    case SET_CURRENT_PLAY_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };

    case AUDIO_PLAYING:
      return {
        ...state,
        isPlaying: action.payload,
      };

    case REPLACE_DATA:
      return handleReplaceData(state, action.payload);

    default:
      return state;
  }
};

export default audioEditor;
