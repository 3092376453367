import { campaignAdAnaltics } from '../../services/listener/Advertise';
import { IAudioEpisode } from '../../types';
import {
  AUDIO_AD_TRACK_PLAY_TIME,
  AUDIO_TRACK_PLAY_TIME,
  CLOSE_AUDIO_PLAYER,
  COMPLETE_AUDIO_AD,
  NEXT_AUDIO,
  PAUSE_AUDIO_TRACK,
  PLAY_AUDIO_TRACK,
  PREVIOUS_AUDIO,
  SET_AUDIO_ADS,
  SET_AUDIO_AD_PLAYER,
  SET_AUDIO_PLAYER,
  SKIP_AUDIO_AD,
} from '../constants/audioPlayer';

import { AudioPlayerDispatch } from '../types/audioPlayer';

// Set audio player
export function setAudioPlayerAction(
  episodes: IAudioEpisode[],
  activeEpisodeId: string,
  isOpen: boolean
) {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: SET_AUDIO_PLAYER,
      payload: { episodes, activeEpisodeId, isOpen },
    });
}

export function setAudioAdPlayerAction(
  audioAds: any[],
  activeAudioAdId: string,
  isOpen: boolean,
  episodes: IAudioEpisode[],
  tempEpisodeId: string
) {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: SET_AUDIO_AD_PLAYER,
      payload: {
        audioAds,
        activeAudioAdId,
        isOpen,
        episodes,
        tempEpisodeId,
      },
    });
}

export function setAudioAdsAction(audioAds: any[]) {
  return (dispatch: AudioPlayerDispatch) => {
    dispatch({
      type: SET_AUDIO_ADS,
      payload: { audioAds },
    });
  };
}

// Close audio player
export function closeAudioPlayerAction() {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: CLOSE_AUDIO_PLAYER,
    });
}

export function completeAudioAdAction() {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: COMPLETE_AUDIO_AD,
    });
}

export function skipAudioAdAction(
  subAdDetailsUuid: string,
  page: string,
  action: string,
  podcastUuid: string,
  episodeUuid: string
) {
  return async (dispatch: AudioPlayerDispatch) => {
    campaignAdAnaltics({
      subAdDetailsUuid,
      page,
      action,
      podcastUuid,
      episodeUuid,
    });
    dispatch({
      type: SKIP_AUDIO_AD,
    });
  };
}

// Next audio
export function nextAudioAction() {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: NEXT_AUDIO,
    });
}

// Next audio
export function prevAudioAction() {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: PREVIOUS_AUDIO,
    });
}

// Play track
export function playAudioTrackAction() {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: PLAY_AUDIO_TRACK,
    });
}

// Pause track
export function pauseAudioTrackAction() {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: PAUSE_AUDIO_TRACK,
    });
}

export function progressAudioAction(value: number) {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: AUDIO_TRACK_PLAY_TIME,
      payload: { value },
    });
}

export function progressAudioAdAction(value: number) {
  return async (dispatch: AudioPlayerDispatch) =>
    dispatch({
      type: AUDIO_AD_TRACK_PLAY_TIME,
      payload: { value },
    });
}
