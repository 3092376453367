import React, { FC, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserNavProps } from './UserNav.Types';
import IconComponent from '../../../../components/Icon/Icon.Component';

const UserNavSection: FC<UserNavProps> = ({ title, navList, onBackClick }) => {
  const location = useLocation();
  const isActive = useCallback(
    (path: string) => (location.pathname === path ? 'active' : ''),
    [location.pathname]
  );
  return (
    <div className='banner-area'>
      <div className='settings-area'>
        <div className='d-flex align-items-center gap-3'>
          {onBackClick && (
            <button type='button' onClick={onBackClick}>
              <IconComponent name='Back' path='dashboard/Back.svg' />
            </button>
          )}
          <h4 className='title mb-0'>{title}</h4>
        </div>
        {navList && (
          <ul className='user-nav'>
            {navList.map((item) => (
              <li>
                <Link to={item.link} className={isActive(item.link)}>
                  <span>{item.label}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default UserNavSection;
