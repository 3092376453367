export const SET_AUDIO_TRACKS_LIST = 'SET_AUDIO_TRACKS_LIST';
export const DUPLICATE_AUDIO_TRACK = 'DUPLICATE_AUDIO_TRACK';
export const SPLIT_AUDIO = 'SPLIT_AUDIO';
export const AUDIO_ACTION = 'CURRENT_ACTION';
export const AUDIO_LOADING = 'AUDIO_LOADING';
export const SET_VOLUME = 'SET_VOLUME';
export const UPDATE_AUDIO_NAME = 'UPDATE_AUDIO_NAME';
export const LOCK_AUDIO_TRACK = 'LOCK_AUDIO_TRACK';
export const ADD_AUDIO_TRACK = 'ADD_AUDIO_TRACK';
export const SET_EDITOR_EPISODES_LIST = 'SET_EDITOR_EPISODES_LIST';
export const SET_AUDIO_SPEED = 'SET_AUDIO_SPEED';
export const SET_MUSIC_TRACK_LIST = 'SET_MUSIC_TRACK_LIST';
export const DELETE_AUDIO_TRACK = 'DELETE_AUDIO_TRACK';
export const UNDO_AUDIO_TRACK = 'UNDO_AUDIO_TRACK';
export const DRAG_AUDIO_TRACK = 'DRAG_AUDIO_TRACK';
export const RESET_AUDIO_EDITOR = 'RESET_AUDIO_EDITOR';
export const LAST_ACTION = 'LAST_ACTION';
export const ACTION_WITHOUT_SAVE_CHANGES = 'ACTION_WITHOUT_SAVE_CHANGES';
export const CHANGES_SAVED = 'CHANGES_SAVED';
export const ACTIVE_AUDIO_TRACK = 'ACTIVE_AUDIO_TRACK';
export const SET_CURRENT_PLAY_TIME = 'SET_CURRENT_PLAY_TIME';
export const SET_AUDIO_DURATION = 'SET_AUDIO_DURATION';
export const REPLACE_DATA = 'REPLACE_DATA';
export const AUDIO_PLAYING = 'AUDIO_PLAYING';
