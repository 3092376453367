import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonWrapper from '../components/form/ButtonWrapper';
import IconComponent from '../components/Icon/Icon.Component';

const NoNetwork = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.onLine) {
      navigate('/');
    }
  }, []);

  return (
    <div className='d-flex flex-column w-100 align-items-center justify-content-center not-found min-vh-100'>
      <IconComponent
        name='ErrorSecondary'
        className='no-network-img'
        path='dashboard/Alert/ErrorSecondary.svg'
      />
      <div className='d-flex flex-column align-items-center justify-content-center gap-3 mt-5'>
        <span className='no-network-title'>Something went wrong</span>
        <p className='not-found-description'>Please check your internet connection and try again</p>
      </div>
      <ButtonWrapper
        className='btn-default no-network-btn btn-lg mt-4'
        onClick={() => navigate('/')}
      >
        Try Again
      </ButtonWrapper>
    </div>
  );
};

export default NoNetwork;
