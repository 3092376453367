import React from 'react';

const RecordAudio = () => (
  <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='40' cy='40' r='40' fill='#9244EB' />
    <g clipPath='url(#clip0_92_5437)'>
      <path
        d='M52.145 41.6762C52.145 41.0577 51.6478 40.5562 51.0343 40.5562C50.4209 40.5562 49.9237 41.0577 49.9237 41.6762C49.9237 47.156 45.5043 51.6138 40.0717 51.6138C34.6395 51.6138 30.2201 47.156 30.2201 41.6762C30.2201 41.0577 29.7229 40.5562 29.1094 40.5562C28.496 40.5562 27.9988 41.0577 27.9988 41.6762C27.9988 48.0138 32.8227 53.2348 38.9611 53.8022V56.8181H34.8395C34.2261 56.8181 33.7288 57.3197 33.7288 57.9385C33.7288 58.5572 34.2261 59.0588 34.8395 59.0588H45.3043C45.9174 59.0588 46.4146 58.5572 46.4146 57.9385C46.4146 57.3197 45.9174 56.8181 45.3043 56.8181H41.1824V53.8022C47.321 53.2351 52.145 48.0138 52.145 41.6762Z'
        fill='white'
        stroke='white'
      />
      <path
        d='M40.0714 48.8494C43.9927 48.8494 47.1829 45.6315 47.1829 41.6761V28.6664C47.1829 24.7114 43.9927 21.4934 40.0714 21.4934C36.1504 21.4934 32.9602 24.7114 32.9602 28.6664V41.6761C32.9602 45.6315 36.1504 48.8494 40.0714 48.8494ZM35.1812 28.6664C35.1812 25.9466 37.375 23.7341 40.0714 23.7341C42.7678 23.7341 44.9616 25.9466 44.9616 28.6664V41.6761C44.9616 44.3959 42.7678 46.6088 40.0714 46.6088C37.375 46.6088 35.1812 44.3959 35.1812 41.6761V28.6664Z'
        fill='white'
        stroke='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_92_5437'>
        <rect
          width='37.2414'
          height='38.2609'
          fill='white'
          transform='translate(21.3796 20.8711)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default RecordAudio;
