import React from 'react';

const HomeIcon = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='me-3'
  >
    <rect width='30' height='30' rx='6' fill='#2342B0' fillOpacity='0.2' />
    <g clipPath='url(#clip0_9213_44306)'>
      <mask id='mask0_9213_44306' maskUnits='userSpaceOnUse' x='5' y='6' width='20' height='18'>
        <path d='M5 6H25V24H5V6Z' fill='white' />
      </mask>
      <g mask='url(#mask0_9213_44306)'>
        <path
          d='M21.1562 11.5C21.9313 12.0911 22.375 13.0168 22.375 13.9915V19.25C22.375 20.9759 20.9759 22.375 19.25 22.375H10.75C9.02409 22.375 7.625 20.9759 7.625 19.25V13.9915C7.625 13.0168 8.07981 12.0979 8.85484 11.5068L13.1048 8.26525C14.2241 7.41159 15.7759 7.41159 16.8952 8.26525L19.25 10.0597V8'
          stroke='#2342B0'
          strokeWidth='1.2'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.65 13.9307C13.1529 13.9307 12.75 14.3337 12.75 14.8307C12.75 15.3278 13.1529 15.7307 13.65 15.7307C14.1471 15.7307 14.55 15.3278 14.55 14.8307C14.55 14.3337 14.1471 13.9307 13.65 13.9307Z'
          fill='#2342B0'
        />
        <path
          d='M16.419 13.9307C15.922 13.9307 15.519 14.3337 15.519 14.8307C15.519 15.3278 15.922 15.7307 16.419 15.7307C16.9161 15.7307 17.319 15.3278 17.319 14.8307C17.319 14.3337 16.9161 13.9307 16.419 13.9307Z'
          fill='#2342B0'
        />
        <path
          d='M13.65 16.7C13.1529 16.7 12.75 17.1029 12.75 17.6C12.75 18.0971 13.1529 18.5 13.65 18.5C14.1471 18.5 14.55 18.0971 14.55 17.6C14.55 17.1029 14.1471 16.7 13.65 16.7Z'
          fill='#2342B0'
        />
        <path
          d='M16.419 16.7C15.922 16.7 15.519 17.1029 15.519 17.6C15.519 18.0971 15.922 18.5 16.419 18.5C16.9161 18.5 17.319 18.0971 17.319 17.6C17.319 17.1029 16.9161 16.7 16.419 16.7Z'
          fill='#2342B0'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_9213_44306'>
        <rect width='16' height='16' fill='white' transform='translate(7 7)' />
      </clipPath>
    </defs>
  </svg>
);

export default HomeIcon;
