import React, { ChangeEvent, FC, ReactNode } from 'react';
import { useField } from 'formik';
import CheckBoxWrapper, { IProps as ICheckboxProps } from '../form/CheckBoxWrapper';

interface IProps extends Omit<ICheckboxProps, 'checked'> {
  customElement?: ReactNode | null;
  handleChange?: CallableFunction;
  isChecked?: boolean;
}

const FormikCheckBox: FC<IProps> = ({
  isChecked,
  name,
  label,
  customElement,
  disabled,
  handleChange,
}) => {
  const [field, meta, helper] = useField({ name, type: 'checkbox' });
  const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (handleChange) {
      handleChange(evt.target.checked);
    } else {
      helper.setValue(evt.target.checked);
    }
  };
  return (
    <CheckBoxWrapper
      name={name}
      id={name}
      label={label}
      checked={(handleChange ? isChecked : field.checked) ?? false}
      onChange={onChange}
      customElement={customElement}
      disabled={disabled}
    />
  );
};

FormikCheckBox.defaultProps = {
  customElement: null,
  isChecked: false,
};

export default FormikCheckBox;
