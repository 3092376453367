import React, { FC, useRef } from 'react';
import { useField } from 'formik';
import { boolean, string } from 'yup';

interface IProps {
  label: string;
  className?: string;
  id: string;
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const RadioButton: FC<IProps> = (props) => {
  const { label, id, className, value, name, checked, disabled, onChange } = props;
  const [, , { setValue }] = useField({ name });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleDivClick = () => {
    inputRef?.current?.click();
  };

  return (
    <button
      type='button'
      className={`radio-btn d-flex justify-content-start align-items-center ${className}`}
      onClick={handleDivClick}
    >
      <input
        ref={inputRef}
        id={id}
        type='radio'
        value={value}
        name={name}
        onChange={(e) => {
          setValue(e?.target?.value);
          if (onChange) {
            onChange(e?.target?.value);
          }
        }}
        defaultChecked={checked}
        disabled={disabled}
      />
      <label htmlFor={id} className={`${className}`}>
        {label}
      </label>
    </button>
  );
};

export default RadioButton;
