import axios, { type AxiosResponse } from 'axios';

import { APP_HOST, TOASTER_STATUS } from '../../../constant';
import { ADVERTISER_API_ROUTES, PODCASTER_API_ROUTES } from '../../../constant/apiRoute';
import { setDataInLocalStorage, showToastMessage } from '../../../utils';
import { ILogin } from '../../../types/auth';
import { API_URL } from '../../../clientConfig';
import { PODCASTER_APP_ROUTES } from '../../../constant/appRoute';
import AxiosClient from '../../AxiosClient';
import { SignupTypes } from '../../../pages/Accounts/Signup/Signup.Types';
import { SignupResponseItemInterface } from './Auth.Typs';

// Signup
export const signupPodcasterService = async (
  userData: SignupTypes,
  successMessage: string,
  handleLoading: (value: boolean) => void,
  _redirect: (value: string) => void
): Promise<AxiosResponse<SignupResponseItemInterface> | any> => {
  try {
    const response = await axios.post(`${API_URL}${PODCASTER_API_ROUTES.SIGN_UP}`, { ...userData });

    if (response.data.success) {
      localStorage.setItem('host', APP_HOST.PODCASTER);
      localStorage.setItem('accessToken', response.data.result.accessToken);
      setDataInLocalStorage(response.data.result);
      // localStorage.setItem(
      //   'roles',
      //   JSON.stringify(['ROLE_PODCASTER', 'ROLE_LISTENER', 'ROLE_ADVERTISER'])
      // );
      showToastMessage(TOASTER_STATUS.SUCCESS, successMessage);
      return response.data.result;
    }
    showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    return null; // Returning a value here as well
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
    return null; // Handle error case by returning null
  } finally {
    handleLoading(false);
  }
};

// Account verification
export const verifyPodcasterService = async (
  token: string,
  successMessage: string,
  redirect: () => void
) => {
  try {
    const response = await AxiosClient.put(`${API_URL}${PODCASTER_API_ROUTES.VERIFY}${token}`);

    if (response.data.success) {
      redirect();
      showToastMessage(TOASTER_STATUS.SUCCESS, successMessage);
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    }
  } catch (error: any) {
    showToastMessage(TOASTER_STATUS.ERROR, error.message);
  }
};

// Login
export const loginService = async (loginData: ILogin) =>
  axios.post(`${API_URL}${PODCASTER_API_ROUTES.LOGIN}`, loginData);

export const checkEmail = async (email: string) => {
  try {
    return await axios.post(`${API_URL}${PODCASTER_API_ROUTES.CHECK_EMAIL}${email}`);
  } catch (error) {
    return null;
  }
};

export const checkEmailVerify = async () => {
  try {
    return await AxiosClient.post(`${API_URL}${PODCASTER_API_ROUTES.CHECK_EMAIL_VERIFY}`);
  } catch (error) {
    return null;
  }
};

export const sendEmail = async (
  email: string,
  handleDisabled: CallableFunction,
  onContinue: CallableFunction
) => {
  try {
    const response = await axios.post(`${API_URL}${PODCASTER_API_ROUTES.SEND_EMAIL}${email}`);
    if (response?.data?.success) {
      onContinue();
      showToastMessage(TOASTER_STATUS.SUCCESS, 'You can now attempt to log in.');
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  } finally {
    handleDisabled(false);
  }
};

export const checkAdvertiserEmail = async (email: string) => {
  try {
    return await axios.post(`${API_URL}${ADVERTISER_API_ROUTES.CHECK_EMAIL}${email}`);
  } catch (error) {
    return null;
  }
};

export const sendEmailAdvertiser = async (
  email: string,
  handleDisabled: CallableFunction,
  onContinue: CallableFunction
) => {
  try {
    const response = await axios.post(`${API_URL}${ADVERTISER_API_ROUTES.SEND_EMAIL}${email}`);
    if (response?.data?.success) {
      onContinue();
      showToastMessage(TOASTER_STATUS.SUCCESS, 'You can now attempt to log in.');
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  } finally {
    handleDisabled(false);
  }
};

export const verifyUserPodcasterService = async (
  token: string,
  successMessage: string,
  redirect: (path: string) => void
) => {
  try {
    const response = await axios.post(`${API_URL}${PODCASTER_API_ROUTES.USER_VERIFY}/${token}`);

    if (response?.data?.success) {
      redirect(`/${PODCASTER_APP_ROUTES.LOGIN}`);
      showToastMessage(TOASTER_STATUS.SUCCESS, successMessage);
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response?.data?.error?.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message
    );
  }
};

export const googleLogin = async (accessToken: string) =>
  axios.post(`${API_URL}${PODCASTER_API_ROUTES.GOOGLE_LOGIN}`, {
    accessToken,
  });

export const linkedinLogin = async (accessToken: string) =>
  axios.post(`${API_URL}${PODCASTER_API_ROUTES.LINKEDIN_LOGIN}`, {
    accessToken,
  });

export const facebookLogin = async (accessToken: string) =>
  axios.post(`${API_URL}${PODCASTER_API_ROUTES.FACEBOOK_LOGIN}`, {
    accessToken,
  });

export const resendEmail = async (email: string) => {
  try {
    const response = await axios.post(`${API_URL}${PODCASTER_API_ROUTES.RESEND_EMAIL}${email}`);
    if (response?.data?.success) {
      showToastMessage(TOASTER_STATUS.SUCCESS, response?.data?.result);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  }
};

export const joinAsAdvertiser = async (email: string) =>
  AxiosClient.post(`${PODCASTER_API_ROUTES.JOIN_AS_ADVERTISER}${email}`);

export const joinAsPodcaster = async (email: string) =>
  AxiosClient.post(`${PODCASTER_API_ROUTES.JOIN_AS_PODCASTER}${email}`);
