import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { APP_HOST } from '../../constant';
import { ADMIN_APP_ROUTES, DASHBOARD_APP_ROUTES, OPEN_APP_ROUTES } from '../../constant/appRoute';

const PublicRoute: FC<{ children: JSX.Element }> = ({ children }) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const HOST = localStorage.getItem('host');

  if (!isAuthenticated) {
    return children;
  }

  if (HOST === APP_HOST.PODCASTER) {
    return <Navigate to={`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.DISCOVER}`} />;
  }
  if (HOST === APP_HOST.ADMIN) {
    return <Navigate to={ADMIN_APP_ROUTES.ROOT} />;
  }
  return <Navigate to={OPEN_APP_ROUTES.ROOT} />;
};

export default PublicRoute;
