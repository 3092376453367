export const SET_AUDIO_PLAYER = 'SET_AUDIO_PLAYER';
export const CLOSE_AUDIO_PLAYER = 'CLOSE_AUDIO_PLAYER';
export const NEXT_AUDIO = 'NEXT_AUDIO';
export const PREVIOUS_AUDIO = 'PREVIOUS_AUDIO';
export const PLAY_AUDIO_TRACK = 'PLAY_AUDIO_TRACK';
export const PAUSE_AUDIO_TRACK = 'PAUSE_AUDIO_TRACK';
export const AUDIO_TRACK_PLAY_TIME = 'AUDIO_TRACK_PLAY_TIME';

export const SET_AUDIO_ADS = 'SET_AUDIO_ADS';
export const SET_AUDIO_AD_PLAYER = 'SET_AUDIO_AD_PLAYER';
export const COMPLETE_AUDIO_AD = 'COMPLETE_AUDIO_AD';
export const AUDIO_AD_TRACK_PLAY_TIME = 'AUDIO_AD_TRACK_PLAY_TIME';
export const SKIP_AUDIO_AD = 'SKIP_AUDIO_AD';
