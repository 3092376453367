import React from 'react';

const RecordIcon = () => (
  <svg width='70' height='70' viewBox='0 0 80 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='40' cy='40.0852' r='38.5' stroke='white' strokeWidth='3' />
    <circle cx='40' cy='40.0852' r='15' fill='#FD4D4D' />
  </svg>
);

export default RecordIcon;
