import React from 'react';

import Check from '../../assets/svg/Check';
import DeleteIcon from '../../assets/svg/DeleteIcon';
import Toast from './Toast';
import IconComponent from '../Icon/Icon.Component';

const toaster = (type: string, message: string) => {
  switch (type) {
    case 'SUCCESS': {
      return (
        <Toast
          icon={<IconComponent path='dashboard/Alert/Success.svg' />}
          colorCode='#e9dafb'
          message={message}
          type='success'
        />
      );
    }
    case 'ERROR': {
      return (
        <Toast
          icon={<IconComponent path='dashboard/Alert/Error.svg' />}
          colorCode='#e9dafb'
          message={message}
          type='delete'
        />
      );
    }
    case 'DELETE': {
      return <Toast icon={<DeleteIcon />} colorCode='#ffd9d9' message={message} type='delete' />;
    }
    case 'INFO': {
      return <Toast icon={<Check />} colorCode='#e9dafb' message={message} type='success' />;
    }

    default:
      return <Toast icon={<Check />} colorCode='#e9dafb' message={message} type='success' />;
  }
};

export default toaster;
