import React from 'react';

const RemoveIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'>
    <rect width='44' height='44' rx='22' fill='#FF5454' />
    <g clipPath='url(#clip0_128_6267)'>
      <path
        d='M23.6491 22.0148L30.6579 15.0058C31.114 14.5499 31.114 13.8127 30.6579 13.3568C30.202 12.9009 29.4649 12.9009 29.009 13.3568L21.9999 20.3659L14.9911 13.3568C14.5349 12.9009 13.798 12.9009 13.3421 13.3568C12.886 13.8127 12.886 14.5499 13.3421 15.0058L20.3509 22.0148L13.3421 29.0239C12.886 29.4798 12.886 30.217 13.3421 30.6729C13.5693 30.9003 13.868 31.0145 14.1666 31.0145C14.4651 31.0145 14.7636 30.9003 14.9911 30.6729L21.9999 23.6638L29.009 30.6729C29.2364 30.9003 29.5349 31.0145 29.8334 31.0145C30.132 31.0145 30.4305 30.9003 30.6579 30.6729C31.114 30.217 31.114 29.4798 30.6579 29.0239L23.6491 22.0148Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_128_6267'>
        <rect width='18' height='18' fill='white' transform='translate(13 13)' />
      </clipPath>
    </defs>
  </svg>
);

export default RemoveIcon;
