import React, { FC, useState, useEffect, useRef } from 'react';

import AudioPlayer from 'react-h5-audio-player';
import { connect, useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import IconButtonWrapper from '../IconButtonWrapper';
import {
  getActiveAudioAd,
  getActiveEpisode,
  getTotalEpisodes,
  getTrackProgress,
  getTrackStatus,
  showAudioPlayer,
} from '../../redux/selectors/audioPlayer';
import { getCookie, getInTitleCase } from '../../utils';
import {
  closeAudioPlayerAction,
  nextAudioAction,
  prevAudioAction,
  playAudioTrackAction,
  pauseAudioTrackAction,
  progressAudioAction,
  completeAudioAdAction,
  progressAudioAdAction,
  skipAudioAdAction,
} from '../../redux/actions/audioPlayer';

import SvgIcons from '../../assets/svg/SvgIcons';
import RemoveAudioIcon from '../../assets/svg/RemoveAudioIcon';
import { IState } from '../../redux/types';
import { IAudioEpisode } from '../../types';

import 'react-h5-audio-player/lib/styles.css';
import './index.scss';
import { episodePlayed } from '../../services/listener/EpisodeDetails';
import { CLOUDINARY_URL } from '../../clientConfig';
import { campaignAdAnaltics } from '../../services/listener/Advertise';

interface IProps {
  activeEpisode: IAudioEpisode | undefined;
  activeAudioAd: any;
  isOpen: boolean;
  closeAudioPlayer: () => void;
  next: () => void;
  prev: () => void;
  total: number;
  play: () => void;
  pause: () => void;
  updateProgress: (value: number) => void;
  updateAdProgress: (value: number) => void;
  isPlayTrack: boolean;
  progress: number;
  completeAudioAdAction: () => void;
  skipAudioAdAction: (
    subAdDetailsUuid: string,
    page: string,
    action: string,
    podcastUuid: string,
    episodeUuid: string
  ) => void;
}

const AudioPlayerWrapper: FC<IProps> = ({
  activeEpisode,
  activeAudioAd,
  isOpen,
  closeAudioPlayer,
  next,
  prev,
  total,
  play,
  pause,
  isPlayTrack,
  updateProgress,
  updateAdProgress,
  progress,
  completeAudioAdAction,
  skipAudioAdAction,
}) => {
  const audioPlayerRef: any = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const userCookieId = getCookie('userCookieId') ?? '0'; // if we are not getting value in that case we are passing 0 in view count api for updating the count

  const activeAudioAdId = useSelector((state: IState) => state?.audioPlayer?.activeAudioAdId);

  const adProgress = useSelector((state: IState) => state?.audioPlayer?.adProgress);

  const audioEpisodes = useSelector((state: IState) => state?.audioPlayer?.episodes);

  const activeEpisodeTempId = useSelector((state: IState) => state?.audioPlayer?.tempEpisodeId);

  useEffect(() => {
    if (isOpen && isPlayTrack) {
      setIsLoading(false);
      setIsPlay(true);
      play();
      audioPlayerRef.current.audio.current.play();
    } else if (isOpen && !isPlayTrack) {
      setIsPlay(false);
      pause();
      audioPlayerRef.current.audio.current.pause();
    }
  }, [activeEpisode, isPlayTrack]);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      setIsPlay(true);
      play();
    }
  }, []);

  const handleLoading = () => setIsLoading(false);

  const handleListen = (e: any) => {
    const { target } = e || {};
    const { currentTime } = target || {};
    if (activeAudioAdId) {
      updateAdProgress(Math.floor(currentTime));
      if (Math.floor(currentTime) === 1) {
        const activeTempEpisode = audioEpisodes?.find(
          (audioEpisode) => audioEpisode?.uuid === activeEpisodeTempId
        );
        campaignAdAnaltics({
          subAdDetailsUuid: activeAudioAdId,
          page: activeTempEpisode?.page || '',
          action: 'LISTEN',
          podcastUuid: activeTempEpisode?.podcastId || '',
          episodeUuid: activeTempEpisode?.uuid || '',
        });
      }
    } else {
      if (target && currentTime < (activeEpisode?.playedDuration || progress || 0)) {
        // Seek to the desired starting time
        target.currentTime = activeEpisode?.playedDuration || progress + 1 || 0;
      }
      updateProgress(Math.floor(currentTime));
    }
  };

  const getActiveAudioButton = () => {
    if (isPlay) {
      return (
        <IconButtonWrapper
          IconName={SvgIcons}
          iconType='pause-audio'
          className='play-pause-btn'
          onClick={() => {
            setIsPlay(false);
            pause();
            if (!activeAudioAdId) {
              episodePlayed(activeEpisode?.uuid || '', progress, parseInt(userCookieId, 10));
            }
          }}
        />
      );
    }
    return (
      <IconButtonWrapper
        IconName={SvgIcons}
        iconType='play-audio'
        className='play-pause-btn'
        onClick={() => {
          setIsPlay(true);
          play();
        }}
      />
    );
  };

  const onClose = () => {
    closeAudioPlayer();
    if (!activeAudioAdId) {
      episodePlayed(activeEpisode?.uuid || '', progress, parseInt(userCookieId, 10));
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='play-fixed-box'>
      {isLoading ? (
        <div className='aliceblue-loader p-5'>
          <Loader />
        </div>
      ) : (
        <>
          {/* eslint-disable-next-line */}
          {activeAudioAd?.adUrl ? (
            adProgress >= 10 ? (
              // eslint-disable-next-line
              <span
                onClick={() => {
                  skipAudioAdAction(
                    activeAudioAdId,
                    activeEpisode?.page || '',
                    'SKIP',
                    activeEpisode?.podcastId || '',
                    activeEpisode?.uuid || ''
                  );
                }}
                className='skip-ad-btn'
              >
                Skip
              </span>
            ) : null
          ) : (
            <IconButtonWrapper
              IconName={RemoveAudioIcon}
              onClick={onClose}
              className='close-audioPlayer'
            />
          )}

          <div className='text-white music-player'>
            <AudioPlayer
              className={activeAudioAd?.adUrl ? 'disable-progress' : ''}
              ref={audioPlayerRef}
              src={
                activeAudioAd?.adUrl
                  ? `${CLOUDINARY_URL}${activeAudioAd?.adUrl}`
                  : (activeEpisode?.url ?? activeEpisode?.episodeUrl ?? '')
              }
              autoPlay={isPlay}
              autoPlayAfterSrcChange={false}
              preload='auto'
              loop={false}
              muted={false}
              header={getInTitleCase(activeAudioAd?.adUrl ? 'Ad' : (activeEpisode?.name ?? ''))}
              customIcons={{
                play: getActiveAudioButton(),
                pause: getActiveAudioButton(),
                forward: (
                  <IconButtonWrapper
                    IconName={SvgIcons}
                    iconType='next-audio'
                    onClick={next}
                    isDisabled={total === activeEpisode?.number || !!activeAudioAdId}
                  />
                ),
                rewind: (
                  <IconButtonWrapper
                    IconName={SvgIcons}
                    iconType='prev-audio'
                    onClick={prev}
                    isDisabled={activeEpisode?.number === 1 || !!activeAudioAdId}
                  />
                ),
              }}
              onLoadedData={handleLoading}
              onListen={handleListen}
              onEnded={() => {
                if (activeAudioAdId) {
                  completeAudioAdAction();
                } else {
                  if (total === (activeEpisode?.index || 0) + 1) {
                    pause();
                  } else {
                    next();
                  }
                  episodePlayed(activeEpisode?.uuid ?? '', progress, parseInt(userCookieId, 10));
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  activeEpisode: getActiveEpisode(state),
  activeAudioAd: getActiveAudioAd(state),
  isOpen: showAudioPlayer(state),
  total: getTotalEpisodes(state),
  isPlayTrack: getTrackStatus(state),
  progress: getTrackProgress(state),
});

const mapDispatchToProps = {
  closeAudioPlayer: closeAudioPlayerAction,
  next: nextAudioAction,
  prev: prevAudioAction,
  play: playAudioTrackAction,
  pause: pauseAudioTrackAction,
  updateProgress: progressAudioAction,
  updateAdProgress: progressAudioAdAction,
  completeAudioAdAction,
  skipAudioAdAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayerWrapper);
