import { createSelector } from 'reselect';
import { v4 as UUID } from 'uuid';

import { IState } from '../../types';
import { ExtendedEditorAudio, IAudioEditor, ISortedTrack } from '../../../types';

const generateAudioTrackData = (tracks: ExtendedEditorAudio[]): ISortedTrack[] => {
  // Group by trackId
  const groupedAudios: { [trackId: string]: ISortedTrack } = tracks.reduce(
    (acc, audio) => {
      const { trackId, order } = audio;
      const newAcc = { ...acc };
      if (!newAcc[trackId]) {
        newAcc[trackId] = { trackId, order, tracks: [] };
      }
      newAcc[trackId].tracks.push(audio);
      return newAcc;
    },
    {} as { [trackId: string]: ISortedTrack }
  );

  // Convert groupedAudios object into an array
  const groupedAudiosArray = Object.values(groupedAudios);

  // Sort each group by order
  groupedAudiosArray.forEach((group) => {
    group.tracks.sort((a, b) => a.order - b.order);
  });

  // Calculate the next order ID
  const maxOrder = Math.max(...groupedAudiosArray.map((group) => group.order));
  const nextOrder = maxOrder !== -Infinity ? maxOrder + 1 : 0;

  // Add an empty track group with the calculated order
  return [
    ...groupedAudiosArray,
    {
      tracks: [],
      order: nextOrder,
      trackId: UUID(),
    },
  ];
};

const calculateDuration = (tracks: ExtendedEditorAudio[]): number => {
  const sortedTracks = generateAudioTrackData(tracks);
  const totalDurations: number[] = [];

  sortedTracks.forEach((sortedTrack) => {
    let trackTotalDuration = 0;

    sortedTrack.tracks.forEach((editorAudio) => {
      // Calculate the total duration of each track based on start time and duration

      const endTime =
        editorAudio.startTime +
        (editorAudio.duration / (editorAudio.duration * editorAudio.speed)) * editorAudio.duration;
      if (endTime > trackTotalDuration) {
        trackTotalDuration = endTime;
      }
    });

    totalDurations.push(trackTotalDuration);
  });

  return Math.max(...totalDurations);
};

export const getAudioEditor = (state: IState) => state.podcaster.audioEditor;

export const getAudioData = createSelector(getAudioEditor, (editor: IAudioEditor) => editor.tracks);

export const getArrangedAudioData = createSelector(getAudioEditor, (editor: IAudioEditor) =>
  generateAudioTrackData(editor.tracks)
);

export const getDuration = createSelector(getAudioEditor, (editor: IAudioEditor) =>
  calculateDuration(editor.tracks)
);

export const getLastAction = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.lastAction
);

export const getRenameAudio = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.renameAudioId
);

export const getEpisodeList = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.episodeList
);

export const getMusicList = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.musicList
);

export const getEpisodeId = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.episodeId
);

export const getDeletedTracks = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.deletedTracks
);

export const getHistory = createSelector(getAudioEditor, (editor: IAudioEditor) => editor.history);

export const getActionWithoutSaveChanges = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.actionWithoutSaveChanges
);

export const getChangesSaved = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.changesSaved
);

export const getActiveAudioTrack = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.activeAudioTrack
);

export const getEditorEpisodeCount = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.episodeList.length
);

export const getCurrentTime = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.currentTime
);

export const getCurrentAction = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.currentAction
);

export const getIsAudioPlaying = createSelector(
  getAudioEditor,
  (editor: IAudioEditor) => editor.isPlaying
);
