import {
  SET_AUDIO_TRACKS_LIST,
  DUPLICATE_AUDIO_TRACK,
  SPLIT_AUDIO,
  SET_VOLUME,
  UPDATE_AUDIO_NAME,
  LOCK_AUDIO_TRACK,
  ADD_AUDIO_TRACK,
  SET_EDITOR_EPISODES_LIST,
  SET_AUDIO_SPEED,
  SET_MUSIC_TRACK_LIST,
  DELETE_AUDIO_TRACK,
  UNDO_AUDIO_TRACK,
  DRAG_AUDIO_TRACK,
  RESET_AUDIO_EDITOR,
  AUDIO_ACTION,
  AUDIO_LOADING,
  LAST_ACTION,
  ACTION_WITHOUT_SAVE_CHANGES,
  CHANGES_SAVED,
  ACTIVE_AUDIO_TRACK,
  SET_CURRENT_PLAY_TIME,
  REPLACE_DATA,
  AUDIO_PLAYING,
  SET_AUDIO_DURATION,
} from '../../constants/podcaster/audioEditor';
import { AudioEditorDispatch, CombinedAudioEditorAndUserDispatch } from '../../types/audioEditor';
import { showToastMessage } from '../../../utils';
import { TOASTER_STATUS } from '../../../constant';
import {
  getAudiosByEpisodeId,
  getEpisodeList,
  getTracks,
} from '../../../services/podcaster/AudioEditor';
import {
  ActionWithoutSaveChanges,
  EditorEpisode,
  EditorMusic,
  ExtendedEditorAudio,
  IMusicItem,
} from '../../../types';
import { EditorActions } from '../../../constant/audioEditor';
import { PODCASTER_APP_ROUTES } from '../../../constant/appRoute';

export const getAudioTracks =
  (id: string, handleLoading?: (data: boolean) => void, isRequiredEpisodeList?: boolean) =>
  async (dispatch: CombinedAudioEditorAndUserDispatch) => {
    try {
      const response = await getAudiosByEpisodeId(id);
      if (response.data.success) {
        if (isRequiredEpisodeList) {
          await dispatch(
            // eslint-disable-next-line
            getEditorEpisodeList(true, handleLoading, () => {}, id, response.data.result)
          );
        } else {
          dispatch({
            type: SET_AUDIO_TRACKS_LIST,
            payload: {
              tracks: response.data.result,
              episodeId: id,
            },
          });
        }
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
      }
    } catch (error: any) {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        error?.response?.data?.result?.errorMessage ?? error.message
      );
    } finally {
      if (handleLoading) handleLoading(false);
    }
  };

export const getMusicTracks = () => async (dispatch: CombinedAudioEditorAndUserDispatch) => {
  try {
    const response: EditorMusic[] = await getTracks(5);
    if (response.length > 0) {
      const musicList: IMusicItem[] = [];
      response?.map((data) => musicList.push(...data.tracks));
      const uniqueMusicItems = Array.from(
        new Map(musicList.map((item) => [item.id, item])).values()
      );
      dispatch({
        type: SET_MUSIC_TRACK_LIST,
        payload: {
          allMusic: uniqueMusicItems.filter((item) => item.audiodownload_allowed),
          recentlyUsedMusic: [],
        },
      });
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  }
};

export const getEditorEpisodeList =
  (
    isEditorPage?: boolean,
    handleLoading?: (data: boolean) => void,
    handleRedirect?: (path: string) => void,
    episodeId?: string,
    audioTracksData?: ExtendedEditorAudio[]
  ) =>
  async (dispatch: CombinedAudioEditorAndUserDispatch) => {
    try {
      const response = await getEpisodeList();
      if (response.data.success) {
        const episodes: EditorEpisode[] = response.data.result;
        dispatch({
          type: SET_EDITOR_EPISODES_LIST,
          payload: episodes,
        });
        if (episodes.length > 0 && isEditorPage) {
          if (!episodeId) {
            await dispatch(getAudioTracks(episodes[0]?.uuid, handleLoading));
          } else if (episodeId && audioTracksData) {
            dispatch({
              type: SET_AUDIO_TRACKS_LIST,
              payload: {
                tracks: audioTracksData,
                episodeId,
              },
            });
          }
          dispatch(getMusicTracks());
        } else if (handleRedirect) handleRedirect(`${PODCASTER_APP_ROUTES.ROOT}`);
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
      }
    } catch (error: any) {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        error?.response?.data?.result?.errorMessage ?? error.message
      );
    }
  };

export const duplicateAudioTrack =
  (value: ExtendedEditorAudio) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: DUPLICATE_AUDIO_TRACK,
      payload: value,
    });

export const splitAudio =
  (value: { track1: ExtendedEditorAudio; track2: ExtendedEditorAudio; id: string }) =>
  async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: SPLIT_AUDIO,
      payload: value,
    });

export const setVolumeLabel =
  (id: string, value: number) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: SET_VOLUME,
      payload: {
        id,
        value,
      },
    });

export const updateAudioName =
  (id: string, name: string) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: UPDATE_AUDIO_NAME,
      payload: {
        id,
        name,
      },
    });

export const lockAudioTrack =
  (id: string, value: boolean) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: LOCK_AUDIO_TRACK,
      payload: {
        id,
        value,
      },
    });

export const addAudioTrack =
  (track: ExtendedEditorAudio) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: ADD_AUDIO_TRACK,
      payload: track,
    });

export const setAudioSpeed = (id: string, value: number) => async (dispatch: AudioEditorDispatch) =>
  dispatch({
    type: SET_AUDIO_SPEED,
    payload: {
      id,
      value,
    },
  });

export const currentAction =
  (id: string, value: EditorActions | '') => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: AUDIO_ACTION,
      payload: { id, value },
    });

export const audioLoading = (id: string, value: boolean) => async (dispatch: AudioEditorDispatch) =>
  dispatch({
    type: AUDIO_LOADING,
    payload: { id, value },
  });

export const lastAction =
  (value: EditorActions | '', id?: string) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: LAST_ACTION,
      payload: { value, id },
    });

export const actionWithoutSaveChanges =
  (value: ActionWithoutSaveChanges) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: ACTION_WITHOUT_SAVE_CHANGES,
      payload: { value },
    });

export const changesSaved = (value: boolean) => async (dispatch: AudioEditorDispatch) =>
  dispatch({ type: CHANGES_SAVED, payload: { value } });

export const activeAudioTrack = (value: string) => async (dispatch: AudioEditorDispatch) =>
  dispatch({ type: ACTIVE_AUDIO_TRACK, payload: { value } });

export const deleteAudioTrack = (id: string) => async (dispatch: AudioEditorDispatch) =>
  dispatch({
    type: DELETE_AUDIO_TRACK,
    payload: id,
  });

export const undoAudioTrack = () => async (dispatch: AudioEditorDispatch) =>
  dispatch({
    type: UNDO_AUDIO_TRACK,
  });

export const dragAudioTrack =
  (id: string, startTime: number) => async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: DRAG_AUDIO_TRACK,
      payload: {
        id,
        startTime,
      },
    });

export const resetAudioEditor = () => async (dispatch: AudioEditorDispatch) =>
  dispatch({
    type: RESET_AUDIO_EDITOR,
  });

export const setCurrentPlayTime = (time: number) => async (dispatch: AudioEditorDispatch) =>
  dispatch({
    type: SET_CURRENT_PLAY_TIME,
    payload: time,
  });

export const replaceData =
  (data: ExtendedEditorAudio, id: string, action: EditorActions | '') =>
  async (dispatch: AudioEditorDispatch) =>
    dispatch({
      type: REPLACE_DATA,
      payload: { data, id, action },
    });

export const setIsAudioPlaying = (playing: boolean) => async (dispatch: AudioEditorDispatch) =>
  dispatch({ type: AUDIO_PLAYING, payload: playing });

export const setAudioDuration =
  (id: string, duration: number) => async (dispatch: AudioEditorDispatch) =>
    dispatch({ type: SET_AUDIO_DURATION, payload: { duration, id } });
