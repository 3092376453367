import React from 'react';

const ColorStart = () => (
  <svg width='22' height='22' viewBox='0 0 132 125' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M131.221 47.2001C130.363 44.5656 128.009 42.6954 125.222 42.4461L87.3762 39.0364L72.4116 4.28354C71.3079 1.73576 68.7947 0.0878296 66.0028 0.0878296C63.211 0.0878296 60.6977 1.73576 59.5941 4.28896L44.6294 39.0364L6.77792 42.4461C3.99698 42.7008 1.64765 44.5656 0.784407 47.2001C-0.0788338 49.8346 0.718845 52.7239 2.82231 54.5453L31.4295 79.4377L22.9937 116.305C22.3764 119.015 23.4363 121.818 25.7037 123.444C26.922 124.317 28.348 124.761 29.7849 124.761C31.0252 124.761 32.2545 124.43 33.3581 123.774L66.0028 104.417L98.6366 123.774C101.024 125.2 104.035 125.07 106.297 123.444C108.564 121.812 109.624 119.01 109.006 116.305L100.571 79.4377L129.178 54.5507C131.281 52.7239 132.084 49.84 131.221 47.2001Z'
      fill='#7D7D7D'
    />
  </svg>
);

export default ColorStart;
