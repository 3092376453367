import { useNavigate } from 'react-router-dom';
import React from 'react';
import ButtonWrapper from '../components/form/ButtonWrapper';
import IconComponent from '../components/Icon/Icon.Component';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='d-flex flex-column w-100 align-items-center justify-content-center not-found min-vh-100'>
      <IconComponent name='404' path='404.svg' />
      <div className='d-flex flex-column align-items-center justify-content-center gap-3 mt-5'>
        <h1>404</h1>
        <span className='not-found-title'>Oops!! Something went wrong</span>
        <p className='not-found-description'>We couldn’t find what you are looking for!</p>
      </div>
      <ButtonWrapper className='btn-default btn-lg mt-4' onClick={() => navigate('/')}>
        Back to Home
      </ButtonWrapper>
    </div>
  );
};

export default NotFound;
