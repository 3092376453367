export const PODCASTER_API_ROUTES = {
  SIGN_UP: 'api/auth/podcaster',
  VERIFY: 'api/auth/podcaster/user-verify?token=',
  RESEND_EMAIL: 'api/auth/podcaster/resend-email?email=',
  USER_VERIFY: 'api/v1/users/verify',
  LOGIN: 'api/auth/podcaster/login',
  GOOGLE_LOGIN: 'api/v1/social/podcaster/login/google',
  LINKEDIN_LOGIN: 'api/v1/social/podcaster/login/linkedin',
  FACEBOOK_LOGIN: 'api/v1/social/podcaster/login/facebook',
  CHECK_EMAIL: 'api/auth/podcaster/check-email?email=',
  SEND_EMAIL: 'api/auth/podcaster/send-mail?email=',
  CHECK_EMAIL_VERIFY: 'api/auth/podcaster/check-email-verified',
  JOIN_AS_ADVERTISER: 'api/v1/user/active-advertiser?email=',
  JOIN_AS_PODCASTER: 'api/v1/user/active-podcaster?email=',
};

export const USER_API_ROUTES = {
  FORGOT_PASSWORD: 'api/v1/users/forgotPassword',
  RESET_PASSWORD: 'api/v1/users/resetPassword',
  CHANGE_PASSWORD: 'api/v1/user/change-password',
  PODCASTER_DETAIL: 'api/v1/user/get-podcaster-details',
  ADMIN_DETAIL: 'api/v1/user/get-admin-details',
  ADVERTISER_DETAIL: 'api/v1/user/get-advertiser-details',
  LISTENER_DETAIL: 'api/v1/user/get-listener-details',
  BANK_DETAILS: 'api/v1/user/bank-details',
  INIVTED_USER_PASSWORD: 'api/v1/users/validateInvite',
  STRIPE_ACCOUNT_DETIALS: 'api/v1/user/stripe-account',
  STRIPE_ACCOUNT_LINK: 'api/v1/user/stripe-account/account-link',
  STRIPE_DISCONNECT: 'api/v1/user/stripe-account/disconnect',
};

export const SOCIAL_LOGIN = {
  GET_PODCASTER_TOKEN: 'api/v1/social/linkedin/access-token',
};

export const PODCASTER_SUBSCRIPTION_API_ROUTES = {
  SUBSCRIPTION_PLANS: 'api/plan/v1?role',
  ALL_PLAN: 'api/plan/v1/role/',
  SET_UP_INTENT: 'api/payment/v1/set-up-intent',
  ADD_PAYMENT_TO_SET_UP_INTENT: 'api/payment/v1/add-payment-method-to-setup-intent',
  ACTIVATE_PLAN: 'api/payment/v1/activate-plan',
  CHECK_COUPON: 'api/plan/v1/check-coupon?couponCode=',
  PAYMENT_PLAN_ACTION: 'api/payment/v1/payment-plan-action',
};

export const LISTENER_SUBSCRIPTION_API_ROUTES = {
  SUBSCRIPTION_PLANS: 'api/v1/listener/get-all-plan?role',
  SET_UP_INTENT: 'api/payment/v1/set-up-intent',
  ADD_PAYMENT_TO_SET_UP_INTENT: 'api/payment/v1/add-payment-method-to-setup-intent',
  ACTIVATE_PLAN: 'api/payment/v1/activate-plan',
  ACTIVE_TRAIL_PLAN: 'api/payment/v1/active-trial-plan',
  CANCEL_PLAN: 'api/payment/v1/cancel-plan',
};

export const PODCASTER_TRANSCRIPT_API_ROUTES = {
  GET_TRANSCRIPT: 'api/v1/transcript',
  UPDATE_TRANSCRIPT: 'api/v1/transcript',
};

export const LISTENER_API_ROUTES = {
  SIGN_UP: 'api/auth/listener',
  VERIFY: 'api/auth/listener/user-verify?token=',
  RESEND_EMAIL: 'api/auth/listener/resend-email?email=',
  LOGIN: 'api/auth/listener/login',
  GOOGLE_LOGIN: 'api/v1/social/listener/login/google',
  LINKEDIN_LOGIN: 'api/v1/social/listener/login/linkedin',
  FACEBOOK_LOGIN: 'api/v1/social/listener/login/facebook',
  USER_VERIFY: 'api/v1/users/verify',
};

export const ADVERTISER_API_ROUTES = {
  SIGN_UP: 'api/auth/advertiser',
  VERIFY: 'api/auth/advertiser/user-verify?token=',
  LOGIN: 'api/auth/advertiser/login',
  RESEND_EMAIL: 'api/auth/advertiser/resend-email?email=',
  GOOGLE_LOGIN: 'api/v1/social/advertiser/login/google',
  LINKEDIN_LOGIN: 'api/v1/social/advertiser/login/linkedin',
  FACEBOOK_LOGIN: 'api/v1/social/advertiser/login/facebook',
  CHECK_EMAIL: 'api/auth/advertiser/check-email?email=',
  SEND_EMAIL: 'api/auth/advertiser/send-mail?email=',
  USER_VERIFY: 'api/v1/users/verify',
};

export const ADVERTISER_CAMPAIGN_API_ROUTES = {
  CAMPAIGN: 'api/campaign/v1',
  BUSINESS: 'api/business/v1',
  IMPRESSIONS: 'api/impression/v1',
  AGE_GROUP: 'api/age-group/v1',
  AD_PLACEMENT: 'api/ad-placement/v1',
  ALL_CAMPAIGN: 'api/campaign/v1/all-campaigns',
  UPLOAD_IMAGE_AUDIO_ADS: 'api/v1/upload/campaign?adType=',
  PAYMENT_INTENT: 'api/payment/v1/payment-intent',
  GET_CAMPAIGN_DETAIL: 'api/campaign/v1/active-ads-count',
};

export const ADVERTISER_ANALYTICS_API_ROUTES = {
  ANALYTICS: 'api/campaign/v1/analytics',
  DOWNLOAD: 'api/campaign/v1/analytics/download/excel',
};

export const ADMIN_API_ROUTES = {
  LOGIN: 'api/auth/admin/login',
  USER_VERIFY: 'api/v1/users/verify',
};

export const PODCASTER_HOME_API_ROUTES = {
  GET_PODCASTS_FILTER: 'api/v1/podcasts/get-filter-details',
};

export const PODCASTER_PODCAST_DETAIL_API_ROUTES = {
  GET_PODCAST_DETAIL: 'api/v1/podcasts/get-podcasts-with-episodes',
  BASE_PODCAST_URL: 'api/v1/podcasts',
  PODCAST_MONETIZE: 'podcast-monetized',
};

export const EPISODE_API_ROUTES = {
  GET_PRESIGNED_URL: 'api/v1/episodes/get-presigned-url',
  UPLOAD_FILE: '/api/v1/upload/transcript/',
  GENERATE_TRANSCRIPT: '/api/v1/upload/generate-transcript/',
  ADD_EPISODE: 'api/v1/episodes',
  COVER_IMAGE: 'api/v1/episodes/upload-episode-cover-image?id=',
  GET_EPISODE: 'api/v1/episodes',
  UPDATE_EPISODE: 'api/v1/episodes',
  UPLOAD_EPISODE_IMAGES: 'api/v1/episodes/upload-episode-images',
  DELETE_EPISODE: 'api/v1/episodes',
  UPLOAD_AUDIO_DURATION: 'api/v1/episodes/get-uploaded-allocated-duration',
  UPDATE_ENHANCE_DURATION: 'api/v1/episodes/save-audio-enhancement-duration',
  EXPORT_AUDIO: 'api/v1/export',
  ENHANCE_PRESIGNED_URL: 'api/v1/upload/get-presigned-urls',
  ACCEPT_AUDIO_UPLOAD: 'api/v1/upload/accept-enhance-audio',
  GET_TRANSCRIPT_STATUS: 'api/v1/upload/get-transcript-status',
  CHECK_TRAILER_EXIST: '/api/v1/podcasts/is-trailer-exist',
};

export const LISTENER_HOME_API_ROUTES = {
  FEATURED_PODCAST: 'api/v1/listener/featured-podcasts',
  CONTINUE_LISTEN_EPISODE: 'api/v1/user/listener/continue-listening-episodes',
  GET_CATEGORIES: 'api/v1/listener/categories',
  GET_SUBSCRIBED_PODCAST: 'api/v1/user/listener/podcasts',
  NEWLY_ADDED_PODCAST: 'api/v1/listener/newly-added-podcast',
  TOP_PODCAST: 'api/v1/listener/top-podcasts',
  GET_SENTIMENTS: 'api/v1/listener/sentiments',
  LISTEN_AGAIN_PODCAST: 'api/v1/user/listener/listen-again-podcasts',
  PODCASTS_MAY_LIKE: 'api/v1/listener/podcasts-may-like',
  GET_PODCASTER: 'api/v1/listener/podcaster-for-listener',
  GET_PODCAST_YOU_FOLLOW: 'api/v1/user/listener/subscribed-podcast',
  SEARCH: 'api/v1/listener/search',
};

export const LISTENER_ADS_API_ROUTES = {
  GET_ADVERTISE: 'api/v1/listener/get-advertize',
  CAMPAIGN_AD_ANALYTICS: 'api/v1/listener/campaign-ad-analytics',
};

export const PODCAST_API_ROUTES = {
  ADD_PODCAST: 'api/v1/podcasts',
  UPDATE_PODCAST: 'api/v1/podcasts',
  GET_PODCAST: 'api/v1/podcasts',
  FEATURED: 'featured-podcast',
  UPDATE_PUBLISHED_PODCAST: 'api/v1/podcasts/published',
  CREATE_RSS_FEED: 'api/v1/podcasts/rss-feed',
  GET_PODCAST_RSS: 'api/v1/listener/get-podcast-rss',
  DELETE_PODCAST: 'api/v1/podcasts',
  GET_EPISODES: 'api/v1/episodes/get-episodes',
  UPLOAD_PODCAST_IMAGES: 'api/v1/podcasts/upload-podcast-images',
  GET_TRAILER_EPISODE: 'api/v1/episodes/get-episode-trailer',
};

export const LISTNER_PODCAST_API_ROUTES = {
  CATEGORY_WITH_PODCAST: 'api/v1/listener/categories-with-podcast',
  PODCAST_WITH_SENTIMENTS: 'api/v1/listener/podcast-with-sentiments',
  PODCAST_BY_CATEGORY: 'api/v1/listener/podcasts-by-category',
  GET_PODCAST_WITH_EPISODE: 'api/v1/listener/get-podcasts-with-episodes',
  SUBSCRIBE_FAVORITE: 'api/v1/podcast/analytics',
  PODCAST_RATING: 'api/v1/podcasts/ratings',
  AUTHOR_SUBSCRIBE_RATING: 'api/v1/podcast/author',
  GET_EPISODES: 'api/v1/listener/get-episodes',
  GET_PODCAST_HISTORY: 'api/v1/user/listener/podcast-history',
  GET_FAVORITES_PODCAST: 'api/v1/user/listener/favourite-podcasts',
  GET_PODCASTER_PODCAST: 'api/v1/listener/podcaster-podcasts',
  WARN_LISTENER: 'api/v1/user-action/type/WARN_LISTENER',
};

export const LISTENER_EPISODE_API_ROUTES = {
  EPISODE: 'api/v1/listener/episode',
  GET_COMMENTS: 'api/v1/listener/comments',
  ADD_COMMENT: 'api/v1/episodes/comments/add',
  REPORT_COMMENT: 'api/v1/episodes/comments/report',
  EPISODE_ANALYTICS: 'api/v1/episodes/analytics',
  EPISODE_PLAYED: 'api/v1/listener/played',
  GET_EPISODES: 'api/v1/listener/get-next-episodes',
  GET_DOWNLOADED_EPISODES: 'api/v1/user/listener/downloaded-episode',
  DELETE_COMMENT: 'api/v1/episodes/comments',
};

export const PODCAST_ANALYTICS_API_ROUTES = {
  VIEW_DOWNLOADS_SUBSCRIBE_COUNT: 'api/v1/podcast/analytics/view-downloads-subscribe-count',
  VIEW_DOWNLOADS_SUBSCRIBE_CHART: 'api/v1/podcast/analytics/view-downloads',
  VIEW_DOWNLOADS_SUBSCRIBE_DOWNLOAD_EXCEL: 'api/v1/podcast/analytics/view-downloads/download/excel',
  TRANSACTION_HISTORY: 'api/v1/podcasts/transaction-history',
  REVENUE_COUNT: 'api/v1/podcast/analytics/revenue-analytics/count',
  REVENUE_ANALYSIS: 'api/v1/podcast/analytics/revenue-analysis',
  REVENUE_DOWNLOAD_EXCEL: 'api/v1/podcast/analytics/revenue-analysis/download/excel',
  ADMIN_WITHDRAWAL_REQUEST: 'api/v1/user/admin-withdrawal-request',
};

export const ADMIN_COMMENT_API_ROUTES = {
  REPORTED_COMMENTS: 'api/v1/admin/reported-comments',
  COMMENT_ACTION: 'api/v1/admin/reported-comment',
};
export const ADMIN_TRANSACTION_HISTORY_API_ROUTES = {
  TRANSACTION_HISTORY: 'api/v1/admin/dashboard/transaction-history',
};

export const ADVERTISER_TRANSACTION_HISTORY_API_ROUTES = {
  ADVERTISER_TRANSACTION_HISTORY: 'api/campaign/v1/transaction-history',
};

export const ADMIN_LISTENER_API_ROUTES = {
  GET_LISTENERS: 'api/v1/admin/listeners',
  INVITE_LISTENER: 'api/v1/admin/send-invite/listener',
  DELETE_LISTENER: 'api/v1/admin/delete',
  BLOCK_LISTENER: 'api/v1/admin/block',
  GET_ACTIVE_PLAN: 'api/v1/admin/user-active-plan',
  GET_PLANS: 'api/plan/v1',
  UPGRADE_PLAN: 'api/v1/admin/upgrade-plan',
  USER_PLAN_COUNT: 'api/v1/admin/user-count?userRole=LISTENER',
  REACTIVATE_USER: 'api/v1/admin/re-active',
  RESEND_INVITE: 'api/v1/admin/resend-invite',
};

export const ADMIN_PODCASTER_API_ROUTES = {
  GET_PODCASTERS: 'api/v1/admin/podcasters',
  GET_ACTIVE_PODCASTERS: 'api/v1/admin/active-podcasters',
  INVITE_PODCASTER: 'api/v1/admin/send-invite/podcaster',
  RESEND_INVITE: 'api/v1/admin/resend-invite',
  DELETE_PODCASTER: 'api/v1/admin/delete',
  BLOCK_PODCASTER: 'api/v1/admin/block',
  GET_ACTIVE_PLAN: 'api/v1/admin/user-active-plan',
  GET_PLANS: 'api/plan/v1',
  UPGRADE_PLAN: 'api/v1/admin/update-plan',
  USER_PLAN_COUNT: 'api/v1/admin/user-count?userRole=PODCASTER',
  REACTIVATE_USER: 'api/v1/admin/re-active',
};

export const ADMIN_ADVERTISER_API_ROUTES = {
  GET_ADVERTISERS: 'api/v1/admin/advertisers',
  INVITE_ADVERTISER: 'api/v1/admin/send-invite/advertiser',
  DELETE_ADVERTISER: 'api/v1/admin/delete',
  BLOCK_ADVERTISER: 'api/v1/admin/block',
  USER_PLAN_COUNT: 'api/v1/admin/user-count?userRole=ADVERTISER',
  REACTIVATE_USER: 'api/v1/admin/re-active',
  RESEND_INVITE: 'api/v1/admin/resend-invite',
};

export const ADMIN_DASHBOARD_API_ROUTES = {
  TOTAL_COUNTS: 'api/v1/admin/dashboard/total-counts',
  TOTAL_REVENUE: 'api/v1/admin/dashboard/total-revenue',
  REVENUE_GENERATE: 'api/v1/admin/dashboard/revenues-generate',
  LAST_CURRENT_MONTH_REVENUE: 'api/v1/admin/dashboard/last-current-month-revenue',
  GENERATE_REPORT: 'api/v1/admin/dashboard/generate-report?timeZone=',
};

export const ADMIN_CAMPAIGN_API_ROUTES = {
  GET_CAMPAIGN: 'api/v1/admin/all-campaigns',
  GET_CAMPAIGN_ADS: 'api/v1/admin/campaign-ad-details',
  CHANGE_CAMPAIGN_STATUS: 'api/v1/admin/change-campaign-status',
  CHANGE_CAMPAIGN_ADS_STATUS: 'api/v1/admin/change-campaign-ad-status',
};

export const ADMIN_PODCAST_API_ROUTES = {
  GET_PODCASTS: 'api/v1/admin/podcaster',
  BLOCK_PODCAST: 'api/v1/admin/block/podcast',
};

export const ADMIN_PROMOCODE_API_ROUTES = {
  GET_PROMOCODE: 'api/v1/admin/coupon',
  GET_ALL_PROMOCODE: 'api/v1/admin/coupon/all',
  GET_SPECIFIC_PROMOCODE: 'api/v1/admin/coupon/',
  ADD_PROMOCODE: 'api/v1/admin/coupon',
  UPDATE_PROMOCODE: 'api/v1/admin/coupon',
  DELETE_PROMOCODE: 'api/v1/admin/coupon',
  CHECK_PROMOCODE: 'api/plan/v1/check-coupon',
  PAUSE_PROMOCODE: 'api/v1/admin/coupon/pause',
};

export const ADMIN_AUDIO_MARK_API_ROUTES = {
  GET_AUDIO_MARK: 'api/v1/admin/audio-track-mark',
  GET_SPECIFIC_AUDIO_MARK: 'api/v1/admin/audio-track/',
  UPLOAD_AUDIO_MARK: 'api/v1/upload/audio-mark-track',
  ADD_AUDIO_MARK: 'api/v1/admin/upload-track-audio-mark',
  UPDATE_AUDIO_MARK: 'api/v1/admin/update-track-audio-mark',
  DELETE_AUDIO_MARK: 'api/v1/admin/audio-track',
};

export const ADMIN_CATEGORY_API_ROUTES = {
  GET_CATEGORY: 'api/v1/categories/admin/all',
  ADD_CATEGORY: 'api/v1/categories',
  DELETE_CATEGORY: 'api/v1/categories',
};

export const ADMIN_MANAGEADMIN_API_ROUTES = {
  GET_MANAGEADMIN: 'api/v1/admin/admins',
  ADD_MANAGEADMIN: 'api/v1/admin/send-invite/admin',
  DELETE_MANAGEADMIN: 'api/v1/admin/delete',
  RESEND_INVITE_MANAGEADMIN: 'api/v1/admin/resend-invite',
  REACTIVATE_USER: 'api/v1/admin/re-active',
};

export const ADMIN_MANAGE_PLAN_API_ROUTES = {
  GET_PLAN: 'api/plan/v1/role',
  UPDATE_PLAN_AMOUNT: 'api/v1/admin/update-plan-amount',
  ADVERTISER_PLAN: 'api/v1/admin/advertiser/plan',
  UPDATE_ADVERTISER_PLAN_AMOUNT: 'api/v1/admin/advertiser/plan/update-amount',
};

export const ADMIN_PAYOUT_API_ROUTES = {
  PODCASTER_PAY_AMOUNT: 'api/v1/admin/podcaster-pay-amount?searchString=',
  PAY_TO_PODCASTER: 'api/v1/admin/pay-to-podcaster',
  MANUAL_PAY: 'api/v1/admin/manual-pay-to-podcaster',
};

export const AUDIO_EDITOR_API_ROUTES = {
  GET_EPISODE_AUDIOS: 'api/v1/episodes/audio-editor',
  GET_EPISODE_LIST: 'api/v1/episodes/audio-editor',
  GET_ALL_MUSIC: 'api/v1/audio-editor/music/all',
  UPDATE_EPISODE_AUDIO_TRACKS: 'api/v1/episodes/audio-editor',
  GET_PRESIGNED_URL: 'api/v1/episodes/audio-editor/get-presigned-url?extension=',
  GET_TRANSCRIPT_BY_EPISODE_UUID: 'api/v1/transcript/episode',
  GENERATE_REPORT_PDF: 'api/v1/transcript/generate/',
  EXPORT_AUDIO: 'api/v1/episodes/audio-editor/download-file',
  DELETE_TRACKS: 'delete-tracks',
  MERGE_AUDIO: 'api/v1/upload/merge-audio',
  FINALIZE: 'api/v1/upload/get-finalize-response',
  DOWNLOAD_AUDIO: 'api/v1/export/download-audio',
};

export const JAMENDO_API_ROUTES = {
  ROOT: 'https://api.jamendo.com/v3.0/',
  ARTISTS_TRACKS: 'artists/tracks/?format=jsonpretty&order=track_name_desc',
  SEARCH_TRACKS: 'autocomplete/?format=jsonpretty&limit=3&prefix=something&matchcount=1',
  PLAYLIST_TRACKS: 'playlists/tracks/?format=jsonpretty&name=instrument&limit=200',
  DOWNLOAD_URL: 'https://prod-1.storage.jamendo.com/',
};
