import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import { faCheck, faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import FormikInputGroup from '../../../components/FormikWrapper/FormikInputGroup';
import { DOMAIN_URL } from '../../../clientConfig';
import { validateSlug } from '../../../services/podcaster/Podcast';
import { useDebounce } from '../../../hooks/useDebounce';

const SlugCustomElement = ({ status }: { status: string }) => {
  if (status === 'loading') {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }
  if (status === 'success') {
    return <FontAwesomeIcon icon={faCheck} className={status} />;
  }
  if (status === 'failure') {
    return <FontAwesomeIcon icon={faCircleExclamation} className={status} />;
  }
  return null;
};

interface PodcastSlugUrlProps {
  slug: string;
  setSlug: (value: string) => void;
  disabled?: boolean;
  initialSlug: string;
}

const PodcastSlugUrl = ({ slug, setSlug, initialSlug, disabled }: PodcastSlugUrlProps) => {
  const [slugStatus, setSlugStatus] = useState<string>('');
  const [field, meta, helper] = useField({ name: 'slugUrl' });

  const debouncedSlug = useDebounce(slug, 500);

  const handleSlugStatus = (value: string) => setSlugStatus(value);

  useEffect(() => {
    if (slugStatus === 'failure') {
      helper.setError('This slug is already in use. Please choose a different one.');
    }
  }, [slugStatus]);

  useEffect(() => {
    if (initialSlug !== slug) {
      if (debouncedSlug) {
        handleSlugStatus('loading');
        validateSlug(slug, handleSlugStatus);
      }
    } else {
      handleSlugStatus('');
    }
  }, [debouncedSlug]);

  const handleSlug = (value: string) => {
    setSlug(value.toLowerCase());
  };

  return (
    <div className='slug-url'>
      <FormikInputGroup
        label='Slug Url'
        name='slugUrl'
        textValue={DOMAIN_URL ?? ''}
        type='text'
        customElement={slug !== '' ? <SlugCustomElement status={slugStatus} /> : null}
        handleChange={handleSlug}
        disabled={disabled}
        value={slug}
        hasValue
        hasError={slugStatus === 'failure'}
        required
      />
    </div>
  );
};

export default PodcastSlugUrl;
