import axios from 'axios';

import { ADMIN_API_ROUTES } from '../../constant/apiRoute';
import { ILogin } from '../../types/auth';
import { API_URL } from '../../clientConfig';
import { showToastMessage } from '../../utils';
import { TOASTER_STATUS } from '../../constant';
import { ADMIN_APP_ROUTES } from '../../constant/appRoute';

export const loginService = async (loginData: ILogin) => {
  const response = await axios.post(`${API_URL}${ADMIN_API_ROUTES.LOGIN}`, loginData);

  return response;
};

export const verifyUserAdminService = async (
  token: string,
  successMessage: string,
  redirect: (path: string) => void
) => {
  try {
    const response = await axios.post(`${API_URL}${ADMIN_API_ROUTES.USER_VERIFY}/${token}`);

    if (response?.data?.success) {
      redirect(`/${ADMIN_APP_ROUTES.LOGIN}`);
      showToastMessage(TOASTER_STATUS.SUCCESS, successMessage);
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response?.data?.error?.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message
    );
  }
};
