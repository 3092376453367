import React from 'react';

const StopIcon = () => (
  <svg width='70' height='70' viewBox='0 0 80 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='40' cy='40.0852' r='38.5' stroke='white' strokeWidth='3' />
    <rect x='28.9658' y='29.2434' width='22.069' height='22.2609' fill='white' />
  </svg>
);

export default StopIcon;
