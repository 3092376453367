import APP_ROUTES, {
  ADMIN_APP_ROUTES as ADMIN_ROUTES,
  ADVERTISER_APP_ROUTES as ADVERTISER_ROUTES,
  DASHBOARD_APP_ROUTES,
  OPEN_APP_ROUTES,
  PODCASTER_APP_ROUTES as PODCASTER_ROUTES,
} from './appRoute';
import ProfileIcon from '../assets/svg/ProfileIcon';
import SvgIcons from '../assets/svg/SvgIcons';
import HomeIcon from '../assets/svg/HomeIcon';

export const MAX_UPLOAD_RECORD_LIMIT = 7200; // In seconds (120 minutes)

export const TOASTER_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  DELETE: 'DELETE',
  INFO: 'INFO',
};

export const SUCCESS_MESSAGE = {
  USER_SIGN_UP:
    'Verification link is sent on your registered email, click on it to activate your account.',
  USER_ACTIVATION:
    'Great news! Your account has been successfully activated, and you can now log in.',
  FORGOT_PASSWORD: 'Password Reset link sent on your email id.',
  RESET_PASSWORD: 'Password reset successfully.',
};

export const LINK_ARGS = {
  path: '',
  children: '',
};

export const USER_ROLE = {
  PODCASTER: 'ROLE_PODCASTER',
  LISTENER: 'ROLE_LISTENER',
  ADVERTISER: 'ROLE_ADVERTISER',
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const APP_HOST = {
  PODCASTER: 'podcaster',
  LISTENER: 'listener',
  ADVERTISER: 'advertiser',
  ADMIN: 'admin',
};

export const NAV_MENU_ITEM = {
  PODCASTER: [
    { url: PODCASTER_ROUTES.ROOT, title: 'Podcasts' },
    {
      url: `${PODCASTER_ROUTES.ROOT}/${PODCASTER_ROUTES.ANALYTICS}`,
      title: 'Analytics',
    },
    {
      url: `${DASHBOARD_APP_ROUTES.DASHBOARD}/${APP_ROUTES.SUBSCRIPTION}`,
      title: 'My Subscription Plan',
    },
  ],
  LISTENER: [
    { url: OPEN_APP_ROUTES.ROOT, title: 'MyPodcast' },
    {
      url: `/${OPEN_APP_ROUTES.DOWNLOADS}`,
      title: 'Downloads',
    },
  ],
  ADVERTISER: [
    { url: ADVERTISER_ROUTES.ROOT, title: 'Campaign' },
    {
      url: `${ADVERTISER_ROUTES.ROOT}/${ADVERTISER_ROUTES.ANALYTICS}`,
      title: 'Analytics',
    },
    {
      url: `${ADVERTISER_ROUTES.ROOT}/${ADVERTISER_ROUTES.TRANSACTION_HISTORY}`,
      title: 'Transaction History',
    },
  ],
};

export const NAV_DROPDOWN_ITEM = {
  PODCASTER: [
    { title: 'MyPodcast', url: '/', Icon: HomeIcon },
    {
      title: 'Create Ad',
      url: '#',
      Icon: SvgIcons,
      iconType: 'create-ad-icon',
    },
    { title: 'Profile', url: '/podcaster/profile', Icon: ProfileIcon },
  ],
  LISTENER: [
    { title: 'Profile', url: '/profile', Icon: ProfileIcon },
    {
      title: 'Create Podcast',
      url: '#',
      Icon: SvgIcons,
      iconType: 'create-podcast',
    },
    {
      title: 'Subscriptions',
      url: '/subscriptions/podcasts',
      Icon: SvgIcons,
      iconType: 'subscription-icon',
    },
    {
      title: 'Favorites',
      url: '/favorites',
      Icon: SvgIcons,
      iconType: 'favorite-icon',
    },
    {
      title: 'Podcast History',
      url: '/podcast-history',
      Icon: SvgIcons,
      iconType: 'histroy-icon',
    },
  ],
  ADVERTISER: [
    {
      title: 'Profile',
      url: '/advertiser/profile',
      Icon: ProfileIcon,
    },
  ],
  ADMIN: [
    {
      title: 'Profile',
      url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.PROFILE}`,
      Icon: ProfileIcon,
    },
    {
      title: 'Manage Admin',
      url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.MANAGE_ADMIN}`,
      Icon: SvgIcons,
      iconType: 'admin_manage',
    },
    {
      title: 'Manage Plan',
      url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.MANAGE_PLAN}`,
      Icon: SvgIcons,
      iconType: 'admin_manage_plans',
    },
  ],
};

export const ADMIN_SIDEBAR_MENU = [
  {
    iconType: 'admin_dashboard',
    title: 'Dashboard',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.DASHBOARD}`,
  },
  {
    iconType: 'admin_podcaster',
    title: 'Podcaster',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.PODCASTER}`,
  },
  {
    iconType: 'admin_listener',
    title: 'Listener',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.LISTENER}`,
  },
  {
    iconType: 'admin_advertiser',
    title: 'Advertiser',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.ADVERTISER}`,
  },
  {
    iconType: 'admin_category',
    title: 'Category',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.CATEGORY}`,
  },
  {
    iconType: 'admin_comment',
    title: 'Comment',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.COMMENT}`,
  },
  {
    iconType: 'admin_settings',
    title: 'Settings',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.SETTINGS}`,
  },
  {
    iconType: 'admin_payout',
    title: 'Payout',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.PAYOUT}`,
  },
  {
    iconType: 'admin_promo_code',
    title: 'Promo Code',
    url: `${ADMIN_ROUTES.ROOT}/${ADMIN_ROUTES.PROMOCODE}`,
  },
];

export const PODCASTS_FILTER_ITEMS = [
  {
    label: 'featured',
    value: 'featured',
    hasSubMenu: false,
    paramsLabel: 'featured',
  },
  {
    label: 'category',
    value: 'podcastCategories',
    hasSubMenu: true,
    paramsLabel: 'categoryUuid',
  },
  {
    label: 'status',
    value: 'podcastStatuses',
    hasSubMenu: true,
    paramsLabel: 'podcastStatus',
  },
  {
    label: 'language',
    value: 'podcastLanguages',
    hasSubMenu: true,
    paramsLabel: 'language',
  },
  {
    label: 'type',
    value: 'podcastTypes',
    hasSubMenu: true,
    paramsLabel: 'typeUuid',
  },
];

export const PODCAST_STATUS = {
  PUBLISH: 'published',
  DRAFT: 'draft',
  SCHEDULE: 'scheduled',
};

export const PLAN_ACTIONS = {
  GENERATE_SUMMARY: 'GENERATE_SUMMARY',
  GENERATE_TRANSCRIPT: 'GENERATE_TRANSCRIPT',
  ADD_BACKGROUND_MUSIC: 'ADD_BACKGROUND_MUSIC',
  ADD_EFFECT: 'ADD_EFFECT',
  DOWNLOAD_RECORDED_FILE: 'DOWNLOAD_RECORDED_FILE',
};

export const CAMPAIGN_FILTER_ITEMS = [
  {
    label: 'LIVE',
    value: 'LIVE',
    hasSubMenu: false,
  },
  {
    label: 'SCHEDULED',
    value: 'SCHEDULED',
    hasSubMenu: false,
  },
  {
    label: 'COMPLETED',
    value: 'COMPLETED',
    hasSubMenu: false,
  },
  {
    label: 'PAUSED',
    value: 'PAUSED',
    hasSubMenu: false,
  },
  {
    label: 'DISABLED',
    value: 'DISABLED',
    hasSubMenu: false,
  },
];

export const ADMIN_PODCASTER_PLAN_FILTER_ITEMS = [
  {
    label: 'free plan user',
    value: 'Free',
    hasSubMenu: false,
    paramsLabel: 'free',
  },
  {
    label: 'standard plan user',
    value: 'Standard',
    hasSubMenu: true,
    paramsLabel: 'standard',
  },
  {
    label: 'pro plan user',
    value: 'Pro',
    hasSubMenu: true,
    paramsLabel: 'pro',
  },
];

export const IMAGE_ADS_PAGE = [
  { label: 'MyPodcast Page', value: 'HOME_PAGE' },
  { label: 'Podcasts Details Page', value: 'PODCASTS_DETAILS_PAGE' },
  { label: 'Stream Episode Page', value: 'STREAM_EPISODE_PAGE' },
];
