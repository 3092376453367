import React, { type FC } from 'react';
import FormikAudioRecorderUploader from '../../../../../../../../../components/FormikWrapper/FormikAudioRecorderUploader';
import { getUploadAudioDuration } from '../../../../../../../../../services/podcaster/Episode';
import { UploadAudioTypes } from './UploadAudio.Types';

const UploadAudioStep: FC<UploadAudioTypes> = ({
  onSetHasAudio,
  initialPodcast,
  isPodcastDraft,
  onAddPodcast,
  redirectToAudioEditor,
  handleAudioRecorderUploaderStage,
  uploadAudioDuration,
  enhanceAudioDuration,
  setIsProcessing,
  handelAudioDuration,
  planUpdated,
  isEdit,
  user,
}) => (
  <div className='col-lg-12'>
    <div className='form-style'>
      <FormikAudioRecorderUploader
        onSetHasAudio={onSetHasAudio}
        uploadedAudio={initialPodcast.uploadedAudio}
        status={initialPodcast.status}
        handleSubmit={async (value: any, redirectOn: any) => {
          if (!isPodcastDraft) await onAddPodcast(value, redirectOn);
        }}
        redirectToAudioEditor={redirectToAudioEditor}
        handleAudioRecorderUploaderStage={handleAudioRecorderUploaderStage}
        setIsProcessing={(value: boolean) => setIsProcessing(value)}
        uploadAudioDuration={uploadAudioDuration}
        enhanceAudioDuration={enhanceAudioDuration}
        refetchLimits={() => getUploadAudioDuration(user.uuid, handelAudioDuration)}
        planUpdated={planUpdated}
        isEdit={isEdit}
      />
    </div>
  </div>
);

export default UploadAudioStep;
