import axios from 'axios';
import { EPISODE_API_ROUTES } from '../../constant/apiRoute';
import AxiosClient from '../../services/AxiosClient';
import { showToastMessage } from '../../utils';
import { TOASTER_STATUS } from '../../constant';

export const uploadAudioFile = async (
  presignedURL: string,
  file: any,
  callBack?: (data: any) => void
) => {
  try {
    const options = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
      onUploadProgress: callBack,
    };
    const result = await axios.put(`${presignedURL}`, file, options);
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const transcriptAndAudioFile = async (
  uuid: string,
  file: File,
  callBack: (data: any) => void,
  isEnhanced?: boolean
) => {
  try {
    const options = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    const data = {
      fileName: file.name,
    };
    const response = await AxiosClient.post(
      `${EPISODE_API_ROUTES.UPLOAD_FILE}${uuid}${isEnhanced ? '?enhanced=true' : ''}`,
      data,
      options
    );

    if (response.data.success === true) {
      await uploadAudioFile(response.data.result.signedUrl, file, callBack);
    }
    return response.data;
  } catch (err) {
    return err;
  }
};

export const acceptEnhanceAudio = async (
  inputFileObject: string,
  handleContinue: () => void,
  handleError: () => void
) => {
  try {
    const response = await AxiosClient.post(EPISODE_API_ROUTES.ACCEPT_AUDIO_UPLOAD, {
      inputFileObject,
    });
    if (response.data?.success) {
      handleContinue();
    }
  } catch (err: any) {
    handleError();
    showToastMessage(TOASTER_STATUS.ERROR, err?.response?.data?.result?.errorMessage);
  }
};
