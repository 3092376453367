import React, { FC, memo } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalType } from './Modal.Types';

import ButtonWrapper from '../ButtonWrapper';
import IconButtonWrapper from '../../IconButtonWrapper';
import CloseIcon from '../../../assets/svg/CloseIcon';

import './index.scss';
import IconComponent from '../../Icon/Icon.Component';
import { useAppContext } from '../../../context/App/AppContext';

const ModalWrapper: FC<ModalType> = ({
  size,
  show,
  body,
  button1,
  button2,
  handleClose,
  customElement,
  isButton,
  bodyClass,
  className,
  modalType,
}) => {
  const { isLightTheme } = useAppContext();
  return (
    <Modal
      size={size}
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      centered
      show={show}
      className={`podcast-successfully payment-successful modal-wrapper ${isLightTheme ? '' : 'is-light-modal'} ${className || ''} modal-${modalType}`}
    >
      {handleClose && (
        <IconButtonWrapper IconName={CloseIcon} onClick={handleClose} className='modal-close-btn' />
      )}
      <Modal.Body className={`${bodyClass}`}>
        <div className='d-flex flex-column align-items-center'>
          {body?.icon && (
            <body.icon.Element iconType={body?.icon.type !== '' ? body?.icon.type : ''} />
          )}
          {body?.imgPath && <IconComponent name='Icon' path={body?.imgPath} className='mb-5' />}
          {body?.title && <h4 className='title'>{body?.title}</h4>}

          {customElement && customElement}
        </div>

        <div>
          {body?.content && <p className='p-0 payment-desc mb-3'>{body?.content}</p>}

          {isButton && (
            <div className={`enhance-btn d-flex ${button2?.children ? 'max-unset' : ''}`}>
              {button1?.children && (
                <ButtonWrapper
                  onClick={button1?.onClick}
                  className={` ${
                    button2?.children ? 'me-md-2 me-sm-0' : 'w-100'
                  } ${button1.className}`}
                  isDisabled={button1.isDisabled}
                >
                  {button1.children}
                </ButtonWrapper>
              )}

              {button2?.children && (
                <ButtonWrapper
                  onClick={button2.onClick}
                  className={`ms-md-2 ms-sm-0 ${button2.className}`}
                  isDisabled={button2?.isDisabled}
                >
                  {button2.children}
                </ButtonWrapper>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default memo(ModalWrapper);
