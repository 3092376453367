import React from 'react';
import { useField } from 'formik';
import moment from 'moment';
import { convertDateTimeFromUnix } from '../../../utils';
import FormikDatepicker from '../../../components/FormikWrapper/FormikDatepicker';

const FeaturedDateDuration: React.FC = () => {
  const [startDateField, startDateMeta, startDateHelpers] = useField('startDate');

  return (
    <div className='row featured-podcast'>
      <div className='col-lg-6'>
        <div className='calendar-container'>
          <FormikDatepicker
            label='Start Date'
            name='startDate'
            required
            minDate={convertDateTimeFromUnix(moment().unix(), 'YYYY-MM-DD')}
          />
        </div>
      </div>
      <div className='col-lg-6'>
        <div className='calendar-container'>
          <FormikDatepicker
            label='End Date'
            name='endDate'
            required
            minDate={convertDateTimeFromUnix(moment(startDateField?.value).unix(), 'YYYY-MM-DD')}
            maxDate={convertDateTimeFromUnix(
              moment(startDateField?.value).add(6, 'days').unix(),
              'YYYY-MM-DD'
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturedDateDuration;
