import { createSelector } from 'reselect';
import { IState } from '../types';
import { IAudioPlayer, IAudioEpisode } from '../../types';

export const getAudioPlayer = (state: IState) => state.audioPlayer;

export const getActiveEpisode = createSelector(getAudioPlayer, (audioPlayer: IAudioPlayer) =>
  audioPlayer.episodes.find(
    (episode: IAudioEpisode) => episode.uuid === audioPlayer.activeEpisodeId
  )
);

export const getActiveAudioAd = createSelector(
  getAudioPlayer,
  (audioPlayer: IAudioPlayer) =>
    audioPlayer.audioAds[0]?.list?.find(
      (audioAd: IAudioEpisode) => audioAd.uuid === audioPlayer.activeAudioAdId
    ) ||
    audioPlayer.audioAds[1]?.list?.find(
      (audioAd: IAudioEpisode) => audioAd.uuid === audioPlayer.activeAudioAdId
    ) ||
    audioPlayer.audioAds[2]?.list?.find(
      (audioAd: IAudioEpisode) => audioAd.uuid === audioPlayer.activeAudioAdId
    )
);

export const showAudioPlayer = createSelector(
  getAudioPlayer,
  (audioPlayer: IAudioPlayer) => audioPlayer.isOpen
);

export const getTotalEpisodes = createSelector(
  getAudioPlayer,
  (audioPlayer: IAudioPlayer) => audioPlayer.episodes.length
);

export const getTrackStatus = createSelector(
  getAudioPlayer,
  (audioPlayer: IAudioPlayer) => audioPlayer.isPlayTrack
);

export const getTrackProgress = createSelector(
  getAudioPlayer,
  (audioPlayer: IAudioPlayer) => audioPlayer.progress
);
