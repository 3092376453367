import moment from 'moment-timezone';
import { getCountryForTimezone } from 'countries-and-timezones';
import { TOASTER_STATUS } from '../../constant';
import { LISTENER_ADS_API_ROUTES } from '../../constant/apiRoute';
import { getCookie, getUserTimeZone, showToastMessage } from '../../utils';
import AxiosClient from '../AxiosClient';

export const getAdvertise = async (
  handleAdvertiseDetail: (advertiseDetail: any) => void,
  handleLoading: (value: boolean) => void,
  data: any
) => {
  try {
    const response = await AxiosClient.post(
      `${LISTENER_ADS_API_ROUTES.GET_ADVERTISE}`,
      // eslint-disable-next-line
      { ...data, country: getCurrentTimezoneCountry() }
    );
    if (response?.data?.success) {
      const advertise = response?.data?.result;

      // Sort each list in imagelist based on displayedCount (ascending order)
      advertise?.imagelist?.forEach((section: any) => {
        section?.list?.sort(
          // eslint-disable-next-line
          (a: any, b: any) => a?.displayedCount - b?.displayedCount
        );
      });

      // Sort each list in audiolist based on displayedCount (ascending order)
      advertise?.audiolist?.forEach((section: any) => {
        section?.list?.sort(
          // eslint-disable-next-line
          (a: any, b: any) => a?.displayedCount - b?.displayedCount
        );
      });

      handleAdvertiseDetail(advertise);
      setTimeout(
        () => {
          getAdvertise(handleAdvertiseDetail, handleLoading, data);
        },
        10 * 60 * 1000
      );
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response?.data?.error?.txt);
    }
  } catch (error: any) {
    showToastMessage(TOASTER_STATUS.ERROR, error?.message);
  } finally {
    handleLoading(false);
  }
};

export const getCurrentTimezoneCountry = () => {
  const timezone = moment.tz.guess(); // Get the current timezone
  return getCountryForTimezone(timezone)?.name;
};

export const campaignAdAnaltics = async (data: {
  subAdDetailsUuid: string;
  page: string;
  action: string;
  podcastUuid: string | null;
  episodeUuid: string | null;
}) => {
  try {
    const userCookieId = getCookie('userCookieId') ?? '0';
    await AxiosClient.post(`${LISTENER_ADS_API_ROUTES.CAMPAIGN_AD_ANALYTICS}`, {
      ...data,
      userCookieId: parseInt(userCookieId, 10),
    });
  } catch (error: any) {
    console.error(error);
  }
};
