import React, { FC } from 'react';
import { useField } from 'formik';
import DatePickerWrapper from '../DateAndTimePickerWrapper/DatePickerWrapper';

interface IProps {
  name: string;
  className?: string;
  label?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  required?: boolean;
  placeholder?: string;
  handleChange?: (value: string) => void;
  disabled?: boolean;
  selectPresentTime?: boolean;
  minDate?: any;
  maxDate?: any;
}

const FormikDatepicker: FC<IProps> = ({
  name,
  className,
  label,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  timeIntervals,
  dateFormat,
  required,
  placeholder,
  handleChange,
  disabled,
  selectPresentTime,
  minDate,
  maxDate,
}) => {
  const [field, meta, handleValue] = useField({ name });

  const onChange = (value: any) => {
    handleValue.setValue(value);
    if (handleChange) {
      handleChange(value);
    }
  };

  return (
    <div>
      <DatePickerWrapper
        name={name}
        className={className}
        label={label}
        value={field.value}
        onChange={onChange}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeCaption={timeCaption}
        timeIntervals={timeIntervals}
        dateFormat={dateFormat}
        required={required}
        error={meta.touched ? meta.error : ''}
        placeholder={placeholder}
        disabled={disabled}
        selectPresentTime={selectPresentTime}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default FormikDatepicker;
