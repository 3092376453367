import { DISABLE_HEADER_ACTION } from '../constants/disableAction';
import { DisableActionsDispatch } from '../types/disableAction';

export function disableAction(value: boolean) {
  return async (dispatch: DisableActionsDispatch) =>
    dispatch({
      type: DISABLE_HEADER_ACTION,
      payload: { value },
    });
}
