import axios from 'axios';
import AxiosClient from '../AxiosClient';
import { AUDIO_EDITOR_API_ROUTES, JAMENDO_API_ROUTES } from '../../constant/apiRoute';
import {
  downloadFileFromUrl,
  showToastMessage,
  downloadAudioFile,
  getLocalStorage,
} from '../../utils';
import { PLAN_ACTIONS, TOASTER_STATUS } from '../../constant';
import { uploadAudioFile } from '../../components/Action/Image&AudioUpload';
import { CLOUDINARY_URL, JAMENDO_CLINT_ID } from '../../clientConfig';
import {
  EditorAudio,
  EditorMusic,
  ExtendedEditorAudio,
  FinalizeAudio,
  FinalizeBackgroundAudioList,
} from '../../types';
import { getSpeedDuration } from '../utils';

export const getAudiosByEpisodeId = (id: string) =>
  AxiosClient.get(`${AUDIO_EDITOR_API_ROUTES.GET_EPISODE_AUDIOS}/${id}`);

export const getEpisodeList = () => AxiosClient.get(AUDIO_EDITOR_API_ROUTES.GET_EPISODE_LIST);

export const getAllMusic = () => AxiosClient.get(AUDIO_EDITOR_API_ROUTES.GET_ALL_MUSIC);

export const getTracks = async (offset: number) => {
  const promises = [...Array(offset)].map(async (data, index) => {
    const response: {
      data: {
        headers: {
          status: string;
          code: number;
          error_message: string;
          warnings: string;
          results_count: number;
        };
        results: EditorMusic[];
      };
    } = await axios.get(
      `${JAMENDO_API_ROUTES.ROOT}${
        JAMENDO_API_ROUTES.PLAYLIST_TRACKS
      }&client_id=${JAMENDO_CLINT_ID}&offset=${index + 1}`
    );
    if (response.data.headers.status !== 'success') {
      throw new Error('Network response was not ok');
    }
    return response.data.results;
  });
  const responses = await Promise.all(promises);
  // Merge responses into a single array
  const mergedResponses = responses.reduce((acc, curr) => acc.concat(curr), []);
  return mergedResponses;
};

export const updateEpisodeAudioTracks = async (
  episodeId: string,
  handleGetAudios: () => void,
  handleLoading: (value: boolean, status: '') => void,
  data: any
) => {
  try {
    const response = await AxiosClient.put(
      `${AUDIO_EDITOR_API_ROUTES.UPDATE_EPISODE_AUDIO_TRACKS}/${episodeId}`,
      data
    );

    if (response.data.success) {
      showToastMessage(TOASTER_STATUS.SUCCESS, response.data.result);
      handleGetAudios();
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  } finally {
    handleLoading(false, '');
  }
};

export const uploadAudioOnAWS = async (
  item: ExtendedEditorAudio,
  index: number
): Promise<ExtendedEditorAudio> => {
  if (
    item.url.includes('blob') ||
    (index !== 0 && item.url.includes('podcast-episodes')) ||
    item.url.includes(JAMENDO_API_ROUTES.DOWNLOAD_URL)
  ) {
    return AxiosClient.post(`${AUDIO_EDITOR_API_ROUTES.GET_PRESIGNED_URL}.wav`)
      .then((presignedResponse) => {
        if (presignedResponse.data.success) {
          const { signedUrl, fileUrl } = presignedResponse.data.result;

          return fetch(item.url)
            .then((response) => response.blob())
            .then((blobData) =>
              uploadAudioFile(signedUrl, blobData).then((uploadResponse) => ({
                ...item,
                url: fileUrl,
              }))
            )
            .catch((error) => {
              throw new Error(error);
            });
        }
        throw new Error(presignedResponse.data.error.txt ?? 'Network Error');
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
  return Promise.resolve(item);
};

const convertTrackToPayload = (index: number, item: ExtendedEditorAudio) => {
  const { duration, locked, name, isPaid, order, speed, trackId, startTime, url, volume, uuid } =
    item;

  const newItem: EditorAudio = {
    name,
    url,
    duration: getSpeedDuration(duration, speed),
    volume: volume * 10,
    speed,
    locked,
    startTime,
    isPaid,
    order,
    trackId,
  };

  if (uuid) {
    newItem.uuid = uuid;
  }

  if (newItem.url.includes('https')) {
    if (index !== 0) {
      const urlList = newItem.url.split('audio-editor');
      newItem.url = urlList.at(-1)?.startsWith('/')
        ? `audio-editor${urlList.at(-1)}`
        : `audio-editor/${urlList.at(-1)}`;
    } else {
      const urlList = newItem.url.split(CLOUDINARY_URL ?? 'net/');
      newItem.url = urlList.at(-1) ?? '';
    }
  }

  return newItem;
};

export const saveEpisodeTracksInDraft = async (
  episodeId: string,
  audioTracksData: ExtendedEditorAudio[],
  deleteTracksData: string[],
  handleLoading: (value: boolean, status: '') => void,
  handleGetAudios: () => void
) => {
  try {
    const responses = await Promise.all(audioTracksData.map(uploadAudioOnAWS));

    if (responses.length === audioTracksData.length) {
      const saveTracks = responses.map((item, index: number) => {
        const payloadItem = convertTrackToPayload(index, item);

        return payloadItem;
      });

      updateEpisodeAudioTracks(episodeId, handleGetAudios, handleLoading, {
        saveTracks,
        deleteTracks: deleteTracksData,
      });
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, 'Network Error');
      handleLoading(false, '');
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
    );
    handleLoading(false, '');
  }
};

export const getTranscriptByEpisodeUuid = async (
  id: string,
  handleSuccess: (data: string) => void
) => {
  try {
    const response = await AxiosClient.get(
      `${AUDIO_EDITOR_API_ROUTES.GET_TRANSCRIPT_BY_EPISODE_UUID}/${id}`
    );

    if (response.data.success) {
      if (response?.data?.result && !response.data.result?.errorMessage)
        handleSuccess(response?.data?.result);
    }
  } catch (error: any) {
    if (error.response.data.result.errorCode !== '4040210')
      showToastMessage(
        TOASTER_STATUS.ERROR,
        error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
      );
  }
};

export const exportPdfReport = async (
  id: string,
  type: string,
  handleLoading: (value: boolean) => void,
  name: string
) => {
  try {
    const response = await AxiosClient.post(
      `${AUDIO_EDITOR_API_ROUTES.GENERATE_REPORT_PDF}${id}?generateType=${type.toUpperCase()}`,
      {},
      {
        responseType: 'blob', // Set responseType as blob object
      }
    );

    // Create blob Object
    const blob = new Blob([response.data]);

    // Convert blob Object into url
    const blobUrl = URL.createObjectURL(blob);

    downloadFileFromUrl('pdf', blobUrl, name);
    URL.revokeObjectURL(blobUrl);
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
    );
  } finally {
    handleLoading(false);
  }
};

export const deleteTracksFromEditor = async (episodeId: string, redirect: () => void) => {
  try {
    const response = await AxiosClient.delete(
      `${AUDIO_EDITOR_API_ROUTES.GET_EPISODE_AUDIOS}/${episodeId}/${AUDIO_EDITOR_API_ROUTES.DELETE_TRACKS}`
    );

    if (response.data.success) {
      redirect();
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
    );
  }
};

export const getFinalizeResponse = async (
  episodeUuid: string,
  handleLoading: (value: boolean, status: '') => void,
  redirect: () => void
) => {
  try {
    const finalizeResponse = await AxiosClient(
      `${AUDIO_EDITOR_API_ROUTES.FINALIZE}/${episodeUuid}`
    );
    if (finalizeResponse?.data?.success) {
      if (finalizeResponse?.data?.result?.finalizeStatus !== 'FINALIZE_SUCCESS') {
        setTimeout(() => {
          getFinalizeResponse(episodeUuid, handleLoading, redirect);
        }, 5000);
      } else {
        await deleteTracksFromEditor(episodeUuid, redirect);
        handleLoading(false, '');
      }
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, finalizeResponse?.data?.error?.txt);
      handleLoading(false, '');
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
    );
    handleLoading(false, '');
  }
};

export const finalizeAudio = async (
  episodeUuid: string,
  fileName: string,
  audioTracksData: ExtendedEditorAudio[],
  handleLoading: (value: boolean, status: '') => void,
  redirect: () => void,
  duration: number
) => {
  try {
    const responses = await Promise.all(audioTracksData.map(uploadAudioOnAWS));

    // Find the track with the minimum startTime
    const mainTrack = responses.reduce(
      (minTrack, currentTrack) =>
        // Compare the startTime of currentTrack with minTrack
        currentTrack.startTime < minTrack.startTime ? currentTrack : minTrack,
      responses[0]
    ); // Initialize minTrack with the first element of the array

    if (responses.length === audioTracksData.length) {
      const backgroundAudioList: FinalizeBackgroundAudioList[] = responses.map((responseItem) => ({
        objectKey: responseItem.url.includes('https')
          ? responseItem.url.split(CLOUDINARY_URL ?? 'net/')[1]
          : responseItem.url,
        startTime: responseItem.startTime,
        decibels: responseItem.volume * 10,
        speedFactor: responseItem.speed,
      }));

      const { url } = mainTrack;

      const splitUrl = url.split(CLOUDINARY_URL ?? 'net/');

      const payload: FinalizeAudio = {
        episodeUuid,
        fileName,
        duration,
        backgroundAudioList,
        mainAudioKey: splitUrl[1] ?? url,
      };

      const mergeResponse = await AxiosClient.post(
        `${AUDIO_EDITOR_API_ROUTES.MERGE_AUDIO}`,
        payload
      );

      if (mergeResponse?.data?.success) {
        getFinalizeResponse(episodeUuid, handleLoading, redirect);
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, mergeResponse?.data?.error?.txt);
        handleLoading(false, '');
      }
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, 'Network Error');
      handleLoading(false, '');
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
    );
    handleLoading(false, '');
  }
};

const downloadAudio = async (
  episodeId: string,
  format: string,
  name: string,
  handleLoading: (value: boolean) => void
) => {
  try {
    const exportResponse = await AxiosClient.get(
      `${AUDIO_EDITOR_API_ROUTES.EXPORT_AUDIO}/${episodeId}`
    );

    if (exportResponse.data.success) {
      const { fileUrl } = exportResponse.data.result;

      downloadAudioFile(format.toLowerCase(), fileUrl, name);
      handleLoading(false);
      (document?.querySelector('.export-audio-dropdown') as HTMLElement)?.click();
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, exportResponse.data.error.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
    );
  }
};

export const exportAudio = async (
  format: string,
  quality: string,
  episodeId: string,
  audioTrack: any,
  name: string,
  handleLoading: (value: boolean) => void
) => {
  try {
    const payload = {
      format: format.toLowerCase(),
      quality: `${quality.split(' ')[0]}k`,
      episodeUuid: episodeId,
      inputFile: audioTrack.split('net/')[1],
      outputFile: `${name}.${format.toLowerCase()}`,
    };

    const response = await AxiosClient.post(`${AUDIO_EDITOR_API_ROUTES.DOWNLOAD_AUDIO}`, payload);

    if (response.status === 200) {
      setTimeout(downloadAudio.bind(null, episodeId, format, name, handleLoading), 5000);
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, 'Network Error');
    }
  } catch (error: any) {
    if (error?.response?.data.message === 'Endpoint request timed out') {
      setTimeout(downloadAudio.bind(null, episodeId, format, name, handleLoading), 5000);
    } else {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        error?.response?.data?.result?.errorMessage ?? error?.message ?? 'Network Error'
      );
    }
  }
};
