import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { AppContextProps } from './Types';

// Provide a default value with an empty object for now (ensure proper typing)
export const CreateContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(CreateContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContext.Provider');
  }
  return context;
};

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContext: React.FC<AppContextProviderProps> = ({ children }) => {
  const [mobile, setMobile] = useState(true);
  const [showItem, setShowItem] = useState(true);
  const [activeMobileMenu, setActiveMobileMenu] = useState(true);
  const [toggleTop, setToggle] = useState(true);
  const [toggleAuth, setToggleAuth] = useState(true);
  const [rightBar, setRightBar] = useState(true);
  const [isLightTheme, setLightTheme] = useState(true);

  const checkScreenSize = () => {
    if (window.innerWidth < 1199) {
      setMobile(false);
      setRightBar(false);
    } else {
      setMobile(true);
      setRightBar(true);
    }
  };
  // ===========> Switcher Function START
  useEffect(() => {
    const themeType = localStorage.getItem('musajjel-theme');
    if (themeType === 'dark') {
      setLightTheme(false);
    }
  }, []);

  useEffect(() => {
    if (isLightTheme) {
      document.body.classList.remove('active-light-mode');
      localStorage.setItem('musajjel-theme', 'light');
    } else {
      document.body.classList.add('active-light-mode');
      localStorage.setItem('musajjel-theme', 'dark');
    }
  }, [isLightTheme]);

  const toggleTheme = () => {
    setLightTheme((prevTheme) => !prevTheme);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const shouldCollapseLeftbar = !mobile;
  const shouldCollapseRightbar = !rightBar;

  const contextValue = useMemo(
    () => ({
      mobile,
      setMobile,
      showItem,
      setShowItem,
      activeMobileMenu,
      setActiveMobileMenu,
      toggleTop,
      setToggle,
      toggleAuth,
      setToggleAuth,
      rightBar,
      setRightBar,
      shouldCollapseLeftbar,
      shouldCollapseRightbar,
      isLightTheme,
      setLightTheme,
      toggleTheme,
    }),
    [mobile, showItem, activeMobileMenu, toggleTop, toggleAuth, rightBar, isLightTheme]
  );

  return <CreateContext.Provider value={contextValue}>{children}</CreateContext.Provider>;
};

export default AppContext;
