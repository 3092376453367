import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import SelectWrapper from '../form/SelectWrapper';

interface IProps {
  label: string;
  className?: string;
  name: string;
  disabled?: boolean;
  options: any;
  selectSuggestion: string;
  required?: boolean;
  handleSelect?: CallableFunction;
}

const FormikSelectWrapper: FC<IProps> = ({
  label,
  name,
  className,
  options,
  selectSuggestion,
  disabled,
  required,
  handleSelect,
}) => {
  const [field, meta] = useField({ name });

  useEffect(() => {
    if (handleSelect) {
      handleSelect(field?.value);
    }
  }, [field?.value]);

  return (
    <SelectWrapper
      label={label}
      name={name}
      className={className}
      onChange={field?.onChange}
      value={field?.value}
      error={meta?.touched ? (meta?.error ?? '') : ''}
      disabled={disabled}
      options={options}
      selectSuggestion={selectSuggestion}
      required={required}
    />
  );
};

FormikSelectWrapper.defaultProps = {
  className: '',
  disabled: false,
  required: false,
};

export default FormikSelectWrapper;
