import React, { FC, useState } from 'react';
import moment from 'moment';
import FormikWrapper from '../../../components/FormikWrapper';
import { featuredPodcastSchema } from '../../../utils/formValidationSchema';
import { setFeaturedPodcast } from '../../../services/podcaster/Podcast';

import '../../../assets/scss/podcast-page.scss';
import { IOpen } from '../../Dashboard/Sections/Pages/PodcastManagement/ManagementPodcast/ManagementPodcast.Types';
import Loader from '../../../components/Loader/Loader';
import FeaturedDateDuration from './FeaturedDateDuration';

interface ISchedule {
  startDate: string;
  endDate: string;
}

interface IProps {
  podcastId: string;
  featured: boolean;
  handleOpen: (value: keyof IOpen) => void;
}

const FeaturedPodcast: FC<IProps> = ({ podcastId, featured, handleOpen }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const handleDisabled = (value: boolean) => setIsDisabled(value);
  const handleValidate = (values: ISchedule) => {
    const errors: { [key: string]: string } = {};

    if (new Date(values.endDate) <= new Date(values.startDate)) {
      errors.endDate = 'Start date must be below end date';
    }

    return errors;
  };

  const onScheduleFeature = (values: ISchedule) => {
    handleDisabled(true);
    setFeaturedPodcast(
      podcastId,
      featured,
      moment(values.startDate).unix(),
      moment(values.endDate).unix(),
      handleOpen,
      handleDisabled
    );
  };

  return (
    <FormikWrapper
      initialValues={{
        startDate: '',
        endDate: '',
      }}
      validationSchema={featuredPodcastSchema}
      button={{
        className: 'm-15 w-50 m-auto',
        children: isDisabled ? <Loader /> : 'Schedule Feature',
        isDisabled,
      }}
      onSubmit={onScheduleFeature}
      validate={handleValidate}
    >
      <FeaturedDateDuration />
    </FormikWrapper>
  );
};

export default FeaturedPodcast;
