import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './tooltip.scss';

interface IProps {
  children: React.ReactNode;
  tooltipProps?: any;
  overlayProps?: any;
  tooltip: React.ReactNode;
  className?: string;
  trigger?: any;
  showTooltip?: boolean; // New prop to control whether to show the tooltip
}

const TooltipWrapper: React.FC<IProps> = ({
  children,
  tooltipProps,
  overlayProps,
  tooltip,
  className,
  trigger,
  showTooltip = true, // Default value is true if not provided
}) => {
  const overlayTooltip = (
    // eslint-disable-next-line
    <Tooltip {...tooltipProps} className={tooltipProps?.className}>
      {tooltip}
    </Tooltip>
  );

  // Conditionally render OverlayTrigger based on showTooltip prop
  return showTooltip ? (
    <OverlayTrigger
      overlay={overlayTooltip}
      // eslint-disable-next-line
      {...overlayProps}
      trigger={trigger}
      className={overlayProps?.className}
    >
      <span id={tooltipProps?.id || ''} className={className}>
        {children}
      </span>
    </OverlayTrigger>
  ) : (
    <span id={tooltipProps?.id || ''} className={className}>
      {children}
    </span>
  );
};

export default TooltipWrapper;

TooltipWrapper.defaultProps = {
  overlayProps: {
    trigger: ['click', 'hover'],
    placement: 'top',
    delayShow: 300,
    delayHide: 200,
    rootClose: true,
  },
  tooltipProps: null,
  className: '',
  trigger: ['hover', 'focus'],
  showTooltip: true, // Default value for the new prop
};
