import React from 'react';

const CloseIcon = () => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.7485 15.0246L29.4299 3.34282C30.1901 2.58297 30.1901 1.3544 29.4299 0.594546C28.67 -0.165304 27.4414 -0.165304 26.6816 0.594546L14.9998 12.2763L3.31843 0.594546C2.55822 -0.165304 1.33 -0.165304 0.570154 0.594546C-0.190051 1.3544 -0.190051 2.58297 0.570154 3.34282L12.2516 15.0246L0.570154 26.7063C-0.190051 27.4662 -0.190051 28.6948 0.570154 29.4546C0.948834 29.8336 1.44674 30.0241 1.94429 30.0241C2.44184 30.0241 2.93939 29.8336 3.31843 29.4546L14.9998 17.7729L26.6816 29.4546C27.0606 29.8336 27.5582 30.0241 28.0557 30.0241C28.5533 30.0241 29.0508 29.8336 29.4299 29.4546C30.1901 28.6948 30.1901 27.4662 29.4299 26.7063L17.7485 15.0246Z'
      fill='#1B0730'
    />
  </svg>
);

export default CloseIcon;
