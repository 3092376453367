import React, { type FC } from 'react';
import { VisualMediaType } from './VisualMedia.Types';
import FormikUploadFileWrapper from '../../../../../../../../../components/FormikWrapper/FormikUploadFileWrapper';

const VisualMediaStep: FC<VisualMediaType> = () => (
  <div className='row form-margin'>
    <div className='row pe-0 mb-30 d-flex flex-column gap-5'>
      <FormikUploadFileWrapper
        columns='col-lg-3'
        accept='image/png, image/jpeg'
        maxFileSize={500}
        sizeIn='KB'
        height={3000}
        width={3000}
        label='Thumbnail'
        name='thumbnail'
        // disabled={
        //   initialPodcast.status.toLowerCase() === PODCAST_STATUS.PUBLISH
        // }
        className='upload-thumbnail-image thumbnail-bg'
      />
      <FormikUploadFileWrapper
        columns='col-lg-10'
        accept='image/png, image/jpeg'
        maxFileSize={10}
        height={400}
        width={1600}
        label='Cover Image'
        name='cover'
        // disabled={
        //   initialPodcast.status.toLowerCase() === PODCAST_STATUS.PUBLISH
        // }
        className='upload-background-image'
      />
    </div>
  </div>
);

export default VisualMediaStep;
