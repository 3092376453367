import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import APP_ROUTES, {
  ADMIN_APP_ROUTES,
  DASHBOARD_APP_ROUTES,
  OPEN_APP_ROUTES,
  PODCASTER_APP_ROUTES,
} from '../constant/appRoute';
import NotFound from './NotFound';
import PublicRoute from '../components/auth/PublicRoute';
import FullPageLoader from '../components/Loader/FullPageLoader';
import NoNetwork from './NoNetwork';
import ScrollToTop from '../Helpers/ScrollToTop.Helper';
import DashboardRouters from './Dashboard.Route';
import PrivateRoute from '../components/auth/PrivateRoute';
import DashboardLayout from '../pages/layout/Dashboard.Layout';

const Layout = lazy(() => import('../pages/layout/Layout'));
const ResetPasswordPage = lazy(() => import('../pages/Accounts/ResetPassword/ResetPassword.Page'));
const SignupPage = lazy(() => import('../pages/Accounts/Signup/Signup'));
const LoginPage = lazy(() => import('../pages/Accounts/Login/Login'));
const ChangePassword = lazy(
  () => import('../pages/Dashboard/Sections/Pages/ChangePassword/ChangePassword.Page')
);
const PricingPage = lazy(() => import('../pages/PricingPage/index'));
const AdminLoginPage = lazy(() => import('../pages/admin/Login/LoginPage'));
const Contact = lazy(() => import('../pages/Contact/Contact'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy'));
const TermAndCondition = lazy(() => import('../pages/TermAndCondition/TermAndCondition'));
const LandingPage = lazy(() => import('../pages/LandingPage'));
const PodcasterForgotPassword = lazy(
  () => import('../pages/Accounts/ForgotPassword/PodcasterForgotPassword')
);
const AdminForgotPassword = lazy(() => import('../pages/admin/ForgotPassword/AdminForgotPassword'));

const Router = () => (
  <Suspense fallback={<FullPageLoader isScreenExist />}>
    <ScrollToTop />
    <Routes>
      {/* <Route path='/' element={}> */}
      <Route
        path={OPEN_APP_ROUTES.ROOT}
        element={
          localStorage.getItem('accessToken') ? (
            <Navigate
              to={`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.DISCOVER}`}
              replace
            />
          ) : (
            <Layout />
          )
        }
      >
        <Route index element={<LandingPage />} />
        <Route path={`${APP_ROUTES.PRIVACY_POLICY}`} element={<PrivacyPolicy />} />
        <Route path={`${APP_ROUTES.TERM_CONDITION}`} element={<TermAndCondition />} />
        <Route path={`${APP_ROUTES.CONTACT_US}`} element={<Contact />} />
        <Route path={`${APP_ROUTES.PRICING}`} element={<PricingPage />} />
        {/* <Route */}
        {/*    path='/*' */}
        {/*    element={ */}
        {/*        <PrivateRoute> */}
        {/*            <AuthorizationGuard/> */}
        {/*        </PrivateRoute> */}
        {/*    } */}
        {/* /> */}
      </Route>
      {/* Dashboard routes */}
      <Route
        path='/*'
        element={
          <PrivateRoute>
            <DashboardRouters />
          </PrivateRoute>
        }
      />

      {/* Admin module public routes */}
      <Route
        path={`${ADMIN_APP_ROUTES.ROOT}${APP_ROUTES.LOGIN}`}
        element={
          <PublicRoute>
            <AdminLoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={ADMIN_APP_ROUTES.ACTIVATION}
        element={
          <PublicRoute>
            <AdminLoginPage />
          </PublicRoute>
        }
      />
      {/* Admin Forgot Password */}
      <Route
        path={`/${ADMIN_APP_ROUTES.FORGOT_PASSWORD}`}
        element={
          <PublicRoute>
            <AdminForgotPassword />
          </PublicRoute>
        }
      />
      {/* End Admin module public routes */}

      {/* Podcaster module public routes */}
      <Route
        path={PODCASTER_APP_ROUTES.SIGN_UP}
        element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        }
      />
      <Route
        path={PODCASTER_APP_ROUTES.VERIFY}
        element={
          localStorage.getItem('accessToken') ? (
            <DashboardLayout />
          ) : (
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          )
        }
      />
      {/*  */}
      <Route
        path={PODCASTER_APP_ROUTES.ACTIVATION}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={PODCASTER_APP_ROUTES.LOGIN}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={`/${PODCASTER_APP_ROUTES.FORGOT_PASSWORD}`}
        element={
          <PublicRoute>
            <PodcasterForgotPassword />
          </PublicRoute>
        }
      />
      {/* Listener Forgot Password */}
      {/* <Route */}
      {/*  path={`${OPEN_APP_ROUTES.FORGOT_PASSWORD}`} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <ListenerForgotPassword /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* Podcaster Forgot Password */}

      {/* Advertiser Forgot Password */}
      {/* <Route */}
      {/*  path={`/${ADVERTISER_APP_ROUTES.FORGOT_PASSWORD}`} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <AdvertiserForgotPassword /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      <Route path={OPEN_APP_ROUTES.PASSWORD_UPDATE} element={<ChangePassword />} />
      <Route
        path={OPEN_APP_ROUTES.RESET_PASSWORD}
        element={
          <PublicRoute>
            <ResetPasswordPage />
          </PublicRoute>
        }
      />
      {/* Listener module public routes */}
      {/* <Route */}
      {/*  path={`${OPEN_APP_ROUTES.LOGIN}`} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <ListenerLoginPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={`${OPEN_APP_ROUTES.SIGN_UP}`} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <ListenerSignupPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={OPEN_APP_ROUTES.VERIFY} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <ListenerLoginPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={OPEN_APP_ROUTES.ACTIVATION} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <ListenerLoginPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={OPEN_APP_ROUTES.INVITED_USER} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <ResetPassword /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={ADVERTISER_APP_ROUTES.SIGN_UP} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <AdvertiserSignupPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={ADVERTISER_APP_ROUTES.LOGIN} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <AdvertiserLoginPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={ADVERTISER_APP_ROUTES.ACTIVATION} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <AdvertiserLoginPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={ADVERTISER_APP_ROUTES.VERIFY} */}
      {/*  element={ */}
      {/*    <PublicRoute> */}
      {/*      <AdvertiserLoginPage /> */}
      {/*    </PublicRoute> */}
      {/*  } */}
      {/* /> */}
      <Route path='/linkedin' element={<LinkedInCallback />} />
      <Route path={OPEN_APP_ROUTES.NO_NETWORK} element={<NoNetwork />} />
      <Route path={OPEN_APP_ROUTES.NOT_FOUND} element={<NotFound />} />
      <Route path={APP_ROUTES.NOT_FOUND} element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default Router;
