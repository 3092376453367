import AxiosClient from '../AxiosClient';
import { EPISODE_API_ROUTES } from '../../constant/apiRoute';
import { showToastMessage, getDateOrTimeFromStatus } from '../../utils';
import { PLAN_ACTIONS, TOASTER_STATUS } from '../../constant';
import { IEpisode, IEpisodeForm } from '../../types/episodeDataInterface';

export const uploadEpisodeImages = async (
  episodeUuid: string,
  thumbnailImage: File | string | null | Blob,
  coverImage: File | string | null | Blob,
  handlePageLoading: (value: boolean) => void
) => {
  try {
    const formData: any = new FormData();
    if (coverImage || thumbnailImage) {
      formData.append('thumbnailImage', thumbnailImage);
      formData.append('coverImage', coverImage);
    }
    const response = await AxiosClient.post(
      `${EPISODE_API_ROUTES.UPLOAD_EPISODE_IMAGES}/${episodeUuid}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (response.data.success) {
      return true;
    }
    handlePageLoading(false);
    showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    return false;
  } catch (error: any) {
    handlePageLoading(false);
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
    return false;
  }
};

export const addEpisode = async (
  episode: IEpisode,
  thumbnailImage: File | string | null | Blob,
  coverImage: File | string | null | Blob,
  handleRedirect: (isAddUpdate: boolean) => void,
  handlePageLoading: (value: boolean) => void,
  redirectOn?: any,
  redirectToAudioEditor?: (id: string, redirectOn: any) => void
) => {
  try {
    const response = await AxiosClient.post(EPISODE_API_ROUTES.ADD_EPISODE, episode);

    if (response.data.success) {
      const episodeImages = await uploadEpisodeImages(
        response.data.result,
        thumbnailImage,
        coverImage,
        handlePageLoading
      );

      if (episodeImages) {
        if (redirectOn && redirectToAudioEditor) {
          redirectToAudioEditor(response.data.result, redirectOn);
        } else {
          handleRedirect(true);
        }
      }
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  } finally {
    handlePageLoading(false);
  }
};

export const generateTranscript = async (
  episodeId: string,
  handleLoading: (value: boolean) => void,
  handleTranscript: (value: any) => void,
  getGeneratedTranscriptData: CallableFunction
) => {
  try {
    const response = await AxiosClient.post(
      `${EPISODE_API_ROUTES.GENERATE_TRANSCRIPT}${episodeId}`
    );

    if (response.data.success) {
      handleTranscript(response.data.result);
      getGeneratedTranscriptData(response.data.result);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
    handleLoading(false);
  }
};
let timeout: any;
export const generateTranscriptStatus = async (
  transcriptUuid: string,
  handleLoading: (value: boolean) => void,
  handleTranscriptStatus: (value: any) => void
) => {
  try {
    const response = await AxiosClient.post(
      `${EPISODE_API_ROUTES.GET_TRANSCRIPT_STATUS}/${transcriptUuid}`
    );

    if (response?.data?.success) {
      handleTranscriptStatus(response?.data?.result);
      if (response.data.result?.transcriptEnum === 'PENDING') {
        timeout = setTimeout(() => {
          generateTranscriptStatus(transcriptUuid, handleLoading, handleTranscriptStatus);
        }, 4000);
      } else {
        handleLoading(false);
      }

      if (response?.data?.result?.transcriptEnum === 'SUCCESS') {
        clearTimeout(timeout);
        if (localStorage.getItem(PLAN_ACTIONS.GENERATE_TRANSCRIPT)) {
          localStorage.removeItem(PLAN_ACTIONS.GENERATE_TRANSCRIPT);
        }
      }
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
    handleLoading(false);
  }
};

const doRedirect = (
  thumbnailImage: File | string | null | Blob,
  coverImage: File | string | null | Blob,
  episodeImagesResponse: any,
  handleRedirect: (isAddUpdate: boolean) => void,
  redirectOn?: any,
  redirectToAudioEditor?: (id: string, redirectOn: any) => void,
  episodeId?: string
) => {
  switch (true) {
    case episodeImagesResponse:
      if (redirectOn && episodeId && redirectToAudioEditor) {
        redirectToAudioEditor(episodeId, redirectOn);
      } else {
        handleRedirect(true);
      }
      break;
    case typeof thumbnailImage === 'string' && typeof coverImage === 'string':
      if (redirectOn && episodeId && redirectToAudioEditor) {
        redirectToAudioEditor(episodeId, redirectOn);
      } else {
        handleRedirect(true);
      }
      break;
    case episodeImagesResponse && typeof coverImage === 'string':
      if (redirectOn && episodeId && redirectToAudioEditor) {
        redirectToAudioEditor(episodeId, redirectOn);
      } else {
        handleRedirect(true);
      }
      break;
    case typeof thumbnailImage === 'string' && episodeImagesResponse:
      if (redirectOn && episodeId && redirectToAudioEditor) {
        redirectToAudioEditor(episodeId, redirectOn);
      } else {
        handleRedirect(true);
      }
      break;
    default:
      break;
  }
};

export const updateEpisode = async (
  episode: IEpisode,
  thumbnailImage: File | string | null | Blob,
  coverImage: File | string | null | Blob,
  handleRedirect: (isAddUpdate: boolean) => void,
  handleUpdating: (value: boolean) => void,
  pathName: string,
  redirectOn?: any,
  redirectToAudioEditor?: (id: string, redirectOn: any) => void,
  isGeneratingTranscript?: boolean,
  handlePaymentPlanAction?: CallableFunction,
  doGenerateTranscript?: CallableFunction,
  draftAndGenerateForPaidUser?: boolean,
  closeSaveAsDraftPopup?: CallableFunction
) => {
  try {
    const response = await AxiosClient.put(
      `${EPISODE_API_ROUTES.UPDATE_EPISODE}/${pathName.split('/')?.at(-2)}`,
      episode
    );

    if (response.data.success) {
      let episodeImagesResponse = null;

      episodeImagesResponse = await uploadEpisodeImages(
        response.data.result,
        typeof thumbnailImage !== 'string' ? thumbnailImage : null,
        typeof coverImage !== 'string' ? coverImage : null,
        handleUpdating
      );

      if (isGeneratingTranscript && handlePaymentPlanAction) {
        handlePaymentPlanAction({
          planName: 'Generate Transcript',
          amount: 1,
          planFeatures: [],
          period: '',
          actionName: [PLAN_ACTIONS.GENERATE_TRANSCRIPT],
        });
        handleUpdating(false);
      } else if (draftAndGenerateForPaidUser && doGenerateTranscript) {
        handleUpdating(false);
        if (closeSaveAsDraftPopup) closeSaveAsDraftPopup();
        doGenerateTranscript();
      } else {
        doRedirect(
          thumbnailImage,
          coverImage,
          episodeImagesResponse,
          handleRedirect,
          redirectOn,
          redirectToAudioEditor,
          pathName.split('/')?.at(-2)
        );
      }
    } else {
      handleUpdating(false);
      showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    }
  } catch (error: any) {
    handleUpdating(false);
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  }
};

export const getEpisodeDetail = async (
  episodeId: string,
  handlePageLoading: (value: boolean) => void,
  handleEpisode: (value: IEpisodeForm) => void,
  handleTranscript: (value: any) => void
) => {
  try {
    const response = await AxiosClient.get(`${EPISODE_API_ROUTES.GET_EPISODE}/${episodeId}`);

    if (response.data.success) {
      const { tags } = response.data.result;
      const { sentiments } = response.data.result;
      const episode: IEpisodeForm = {
        thumbnail: response.data.result.thumbnailImage,
        cover: response.data.result.coverImage,
        episodeNo: response.data.result.episodeNo,
        episodeTitle: response.data.result.name,
        description: response.data.result.description,
        tags:
          tags?.length > 0
            ? tags.map((tag: any) => ({
                key: tag.uuid,
                value: tag.tagName,
              }))
            : [],
        episodeCountry: response.data.result.episodeCountry,
        date: getDateOrTimeFromStatus(
          response.data.result.episodePublishedOrScheduledDate,
          'YYYY-MM-DD',
          response.data.result.publishStatus
        ),
        time: getDateOrTimeFromStatus(
          response.data.result.episodePublishedOrScheduledDate,
          'HH:mm',
          response.data.result.publishStatus
        ),
        status: response.data.result.publishStatus,
        uploadedAudio: response.data.result.episodeUrl,
        enhancedAudio: null,
        blobUrl: null,
        recordedAudio: null,
        processedAudio: null,
        sentimentNames:
          sentiments?.length > 0 ? sentiments.map((sentiment: any) => sentiment.sentimentName) : [],
        transcriptStatus: response.data.result.transcriptStatus,
        transcriptUuid: response.data.result?.transcriptTransfer?.uuid ?? '',
        podcastUuid: response.data.result?.podcastUuid,
        trailer: response.data.result?.trailer,
      };

      handleEpisode(episode);
      handleTranscript({
        uuid: response.data.result?.transcriptTransfer?.uuid ?? '',
        transcriptData: response.data.result?.transcriptTransfer?.transcriptData ?? '',
      });
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error.message
    );
  } finally {
    handlePageLoading(false);
  }
};

export const deleteEpisode = async (
  episodeUuid: string,
  handleLoading: (value: boolean) => void,
  doGetEpisodes: () => void,
  handleUpdateCount: CallableFunction,
  isTrailer: boolean
) => {
  try {
    const response = await AxiosClient.delete(
      `${EPISODE_API_ROUTES.DELETE_EPISODE}/${episodeUuid}`
    );

    if (response?.data?.success) {
      doGetEpisodes();
      handleUpdateCount();
      showToastMessage(
        TOASTER_STATUS.SUCCESS,
        isTrailer ? 'Trailer deleted successfully' : 'Episode deleted successfully'
      );
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response?.data?.error?.txt);
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message
    );
  } finally {
    handleLoading(false);
  }
};

export const getUploadAudioDuration = async (
  userUUid: string,
  onContinue: (data: {
    totalAllowedEnhanced: number;
    totalAllowedUpload: number;
    usedEnhanced: number;
    usedUpload: number;
  }) => void
) => {
  try {
    const response = await AxiosClient.get(
      `${EPISODE_API_ROUTES.UPLOAD_AUDIO_DURATION}/${userUUid}`
    );

    if (response?.data?.success) {
      onContinue(response.data.result);
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, response?.data?.error?.txt);
    }
  } catch (error: any) {
    // showToastMessage(
    //   TOASTER_STATUS.ERROR,
    //   error?.response?.data?.result?.errorMessage ?? error?.message
    // );
  }
};

export const updateEnhanceDuration = async (enhancedDuration: number) => {
  const response = await AxiosClient.post(EPISODE_API_ROUTES.UPDATE_ENHANCE_DURATION, {
    enhancedDuration,
  });
  return response?.data;
};

export const exportAudioWithAudioMark = async (objectKey: string, checkPlan: boolean = true) => {
  const response = await AxiosClient(
    `${EPISODE_API_ROUTES.EXPORT_AUDIO}/${objectKey}?checkPlan=${checkPlan}`
  );

  if (response?.data?.result?.audioMarkStatus === 'SUCCESS') {
    return response.data;
  }
  if (response?.data?.result?.audioMarkStatus === 'PENDING') {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const result = await exportAudioWithAudioMark(objectKey, checkPlan);
        resolve(result);
      }, 5000);
    });
  }

  return null;
};

export const checkTrailerExist = async (podcastUuid: string, episodeUuid: string) => {
  try {
    const response = await AxiosClient(
      `${EPISODE_API_ROUTES.CHECK_TRAILER_EXIST}?podcastUuid=${podcastUuid}&episodeUuid=${episodeUuid}`
    );
    if (response?.data?.success) {
      return response.data.result;
    }
  } catch (error: any) {
    showToastMessage(
      TOASTER_STATUS.ERROR,
      error?.response?.data?.result?.errorMessage ?? error?.message
    );
  }
  return null;
};
