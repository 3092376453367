import React from 'react';
import { Modal } from 'react-bootstrap';
import './permission-modal.scss';
import CloseIcon from '../../assets/svg/CloseIcon';
import { MicrophonePermissionModalInterface } from '../../types/modelInterface';
import SvgIcons from '../../assets/svg/SvgIcons';
import IconButtonWrapper from '../IconButtonWrapper';

const MicrophonePermissionModal = (props: MicrophonePermissionModalInterface) => {
  const { onHide } = props;

  return (
    <div className=' modal-dialog-centered podcast-successfully modal-content'>
      <div className='modal-content'>
        <Modal
          // eslint-disable-next-line
          {...props}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          className='podcast-successfully enable-microphone-popup'
        >
          <IconButtonWrapper IconName={CloseIcon} onClick={onHide} className='modal-close-btn' />
          <Modal.Body className='modal-body podcast-successfully py-5'>
            <div className=' d-flex justify-content-center'>
              <SvgIcons iconType='enable-mirophone-modal' />
            </div>
            <p className='mw-100 text-wrap py-2 title'>
              Musajjel has been blocked from using your microphone
            </p>
            <p className='text-wrap pt-2 fs-5 lh-base text-dark body-content'>
              Click the
              <SvgIcons iconType='enable-browser-setting' /> page info icon in your browser’s
              address bar and Turn on Microphone.
            </p>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default MicrophonePermissionModal;
