import React, { FC } from 'react';
import Empty from '../../assets/images/light/dashboard/Box.png';
import EmptyDark from '../../assets/images/dark/dashboard/Box.png';

import { EmptyTypes } from './Empty.Types ';
import { useAppContext } from '../../context/App/AppContext';

const EmptyComponent: FC<EmptyTypes> = ({
  title,
  desc,
  icon = Empty,
  button,
  onClickHandler,
  IconElement,
  className,
}) => {
  const { isLightTheme } = useAppContext();
  return (
    <div
      className={`empty-section d-flex flex-column justify-content-center align-items-center mx-lg-5 text-center ${className}`}
    >
      <img src={icon} className={`${isLightTheme ? 'dark-img' : ''} mb-5`} alt={title} />
      <h4 className='mt--4 mb-3'>{title}</h4>
      <p className='mb--0'>{desc}</p>
      {button && (
        <button
          type='button'
          className='btn-default btn-small d-flex align-items-center mt-5'
          onClick={onClickHandler}
        >
          {' '}
          {IconElement && IconElement}
          {button}
        </button>
      )}
    </div>
  );
};

export default EmptyComponent;
